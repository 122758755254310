import React from 'react';
import styled from 'styled-components';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import useMobile from '../util/useMobile';
import { ScrollDownBox } from './Main';

const MainBg = styled.div`
    position: relative;
    width: 100%;
    height: 100svh;
    background: ${(props)=> `url(${props.$bgUrl}) no-repeat center`};
    background-size: cover;
`

const MainSectionTxtWrap = styled.div`
    position: absolute;
    top: 49%;
    right: 26.5%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width:1024px) {
        padding: 0px 20px;
        top: 45.5%;
        left: 0;
        right: 0;
    }
`

const MainSectionTxt = styled.div`
    font-size: 1.125rem;
    line-height: 2;
    font-weight: 400;
    &.port {
        font-size: 3.75rem;
        line-height: 1;
        font-weight: 700;
        @media (max-width:1024px) {
            font-size: 2.25rem;
        }
    }
    @media (max-width:1024px) {
        font-size: 0.875rem;
        line-height: 1;
    }
`

const AboutLayout = styled.div`
    padding: 0px 40px;
    @media (max-width:1024px) {
        padding: 0px;
    }
`

const AboutTitleImgWrap = styled.div`
    position: relative;
    img {
        width: 100%;
        &.logo {
            max-width: 300px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            @media (max-width:1024px) {
                max-width: 160px;
            }
        }
    }
`

const AboutBoxWrap = styled.div`
    max-width: 1840px;
    margin: 200px auto 150px;
    display: flex;
    gap: 40px;
    @media (max-width: 1024px) {
        flex-wrap: wrap;
        gap: 0;
        padding: 0px 20px;
        margin: 150px auto 100px;
    }
`

const AboutLogoWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 50%;
    @media (max-width:1024px) {
        flex-basis: 100%;
    }
`

const AboutLogo = styled.div`
    max-width: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width:1024px) {
        max-width: 250px;
        padding-bottom: 150.19px;
    }
    img {
        width: 100%;
    }
`

const AboutBox = styled.div`
    flex-basis: 50%;
    @media (max-width:1024px) {
        flex-basis: 100%;
    }
`

const AboutBoxTilte = styled.div`
    font-size: 1.5rem;
    margin-bottom: 50px;
    line-height: 1;
    letter-spacing: -0.24px;
    @media (max-width:1024px) {
        margin-bottom: 30px;
        line-height: 1.5;
        span {
            display: block;
        }
    }
`

const AboutBoxSub = styled.div`
    max-width: 730px;
    @media (max-width:1024px) {
        max-width: none;
        width: 100%;
    }
`

const AboutBoxSubTitle = styled.div`
    font-size: 1.125rem;
    font-weight: 400;
    span {
        display: block;
    }
    @media (max-width:1024px) {
        font-size: 0.875rem;
        span {
            display: inline;
        }
    }
`

const AboutWe = styled.div`
    padding: 200px 0px;
    @media (max-width:1024px) {
        padding: 150px 20px 200px;
    }
`

const AboutWeWrap = styled.div`
    max-width: 1460px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const AboutWeTitle = styled.div`
    font-size: 3.125rem;
    line-height: 1;
    list-style: -0.4px;
    @media (max-width:1024px) {
        font-size: 2.5rem;
    }
`

const AboutWeSubTitle = styled.div`
    font-size: 1.125rem;
    font-weight: 400;
    margin-top: 30px;
    line-height: 1;
    @media (max-width:1024px) {
        font-size: 0.875rem;
    }
`

const AboutIcoBoxWrap = styled.div`
    width: 100%;
    padding-top: 107px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width:1024px) {
        flex-wrap: wrap;
        padding-top: 14px;
        flex-direction: column;
    }
`

const AboutIcoBox = styled.div`
    max-width: 324px;
    @media (max-width:1024px) {
        max-width: none;
        width: 100%;
        padding: 100px 0px;
        border-bottom: 1px solid #DADADA;
        &:last-child{
            border-bottom: none;
        }
    }
`

const AboutIcoImg = styled.div`
    width: 80px;
    @media (max-width:1024px) {
        width: 60px;
    }
    &.ico2 {
        width: 90px;
        @media (max-width:1024px) {
            width: 70px;
        }
    }
    img {
        width: 100%;
    }
`

const AboutIcoSubTitle = styled.div`
    font-size: 1.125rem;
    font-weight: 400;
    padding-top: 30.62px;
    @media (max-width:1024px) {
        font-size: 0.875rem;
        padding-top: 20.71px;
    }
`

const AboutIcoTitle = styled.div`
    font-size: 1.5rem;
    font-weight: 700;
    padding-top: 10px;
    @media (max-width:1024px) {
        padding-top: 0;
    }
`

const AboutIcoDesc = styled.div`
    padding-top: 50px;
    font-size: 1.125rem;
    font-weight: 400;
    @media (max-width:1024px) {
        padding-top: 30px;
        font-size: 1rem;
    }
`

const AboutIcoLine = styled.div`
    width: 1px;
    height: 484px;
    background-color: #DADADA;
    margin: 0px 20px;
    @media (max-width:1024px) {
        display: none;
    }
`

const VideoContainer = styled.div`
    max-width: 1460px;
    margin: 0 auto;
    padding-bottom: 200px;
    display: flex;
    align-items: center;
    gap: 100px;
    @media (max-width:1024px) {
        flex-wrap: wrap;
        gap: 50px;
    }
`

const VideoWrap = styled.div`
    position: relative;
    width: 730px;
    height: 700px;
    @media (max-width:1024px) {
        flex-basis: 100%;
        height: auto;
    }
`

const Video = styled.video`
    width: 100%;
    height: 100%;
`

const VideoTxtWrap = styled.div`
    padding-right: 20px;
    @media (max-width:1024px) {
        flex-basis: 100%;
        padding: 0px 20px;
    }
`

const VideoSubTitle = styled.div`
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 1.875rem;
`

const VideoTxtTitle = styled.div`
    font-size: 3.125rem;
    font-weight: 700;
    margin-bottom: 50px;
    line-height: 3.875rem;
    @media (max-width:1024px) {
        font-size: 2.5rem;
    }
`

const VideoTxt = styled.div`
    font-size: 1.125rem;
    line-height: 1.875rem;
    @media (max-width:1024px) {
        font-size: 1rem;
        span {
            display: block;
        }
    }
`

const VideoLogo = styled.div`
    width: 227px;
    height: 16px;
    margin-top: 50px;
    img {
        width: 100%;
    }
    @media (max-width:1024px) {
        width: 187px;
        height: 14px;
    }
`

function CompanyIntro() {

    const isMobile = useMobile();
    
    return (
        <>
            <Header isVisible={true} />
            <MainBg $bgUrl={isMobile ? './../img/company_main_mobile.png' : './../img/company_main.png'}>
                <MainSectionTxtWrap>
                    <MainSectionTxt className='port'>회사 소개</MainSectionTxt>
                    <MainSectionTxt>리브랜더를 소개합니다</MainSectionTxt>
                </MainSectionTxtWrap>
                <ScrollDownBox>
                    <img src={isMobile ? './../img/scroll_down_mobile.png' : './../img/scroll_down.png'} alt='scroll_down' />
                </ScrollDownBox>
            </MainBg>
            <AboutLayout>
                <AboutBoxWrap>
                    <AboutLogoWrap>
                        <AboutLogo>
                            <img src='./img/logo_big.png' alt='logo_big' />
                        </AboutLogo>
                    </AboutLogoWrap>
                    <AboutBox>
                        <AboutBoxTilte className='ralewayB'>We make your dreams<span> come true</span></AboutBoxTilte>
                        <AboutBoxSub>
                            <AboutBoxSubTitle>
                                <p>[리:브랜더]는 프랜차이즈 인큐베이팅 회사입니다.</p>
                                <p>매장 영업이 잘 되고 성장 가능성이 있지만 바쁘거나 또는 방법을 몰라서 주저하시는 개인 또는 법인이 저희의 주 고객입니다.</p><br/>
                                <p>COVID-19 3년, 대형 프랜차이즈 본사들도 그 기간 동안 신규매장 유치를 하지 못한 상황에서<span>브랜드 매장 50개 이상 유치한 담당자가 가맹본부 인큐베이팅을 하면 어떨까요?</span></p>
                                <p>저희 리브랜더는 불황 속에 성공하는 가맹본부를 이끌어본 경험으로 가맹사업을 시작하려는 개인 또는 법인이 가장 번거롭고 까다로울 문제들만 선별해 B2B 서비스를 제공합니다.</p><br/>
                                <p>리브랜더 인큐베이팅의 가장 큰 특징은 고객사에게 직접 비용을 청구하지 않는 것입니다.</p>
                                <p>마음 편히 장사에만 집중하세요! 가맹사업에 필요한 나머지 업무는 저희가 맡겠습니다.</p>
                            </AboutBoxSubTitle>
                        </AboutBoxSub>
                    </AboutBox>
                </AboutBoxWrap>
                <AboutTitleImgWrap>
                    <img src={isMobile ? './img/company_about_mobile.png' : './img/company_about.png'} alt='company_about' />
                    <img src='./img/logo_black.png' className='logo' />
                </AboutTitleImgWrap>
                <AboutWe>
                    <AboutWeWrap>
                        <AboutWeTitle className='ralewayB'>What we do?</AboutWeTitle>
                        <AboutWeSubTitle>리브랜더는 가장 핵심적인 3가지 역할을 수행합니다</AboutWeSubTitle>
                        <AboutIcoBoxWrap>
                            <AboutIcoBox>
                                <AboutIcoImg>
                                    <img src='./img/about_01.png' alt='about_01' /> 
                                </AboutIcoImg>
                                <AboutIcoSubTitle>복잡해지는</AboutIcoSubTitle>
                                <AboutIcoTitle>정보공개서 등록</AboutIcoTitle>
                                <AboutIcoDesc>
                                    <p>복잡해지는 「가맹사업법」에 따라</p>
                                    <p>가맹본부가 법률과 시행령을 준수하여</p>
                                    <p>운영할 수 있도록 하며</p>
                                    <p>공정거래위원회 또는 시도지사에 신청하는</p>
                                    <p>브랜드 신규등록업무도 대행합니다.</p><br/>
                                    <p>이 과정에서 행정, 법률, 세무 등에 관한</p>
                                    <p>종합적인 컨설팅을 진행합니다.</p>
                                </AboutIcoDesc>
                            </AboutIcoBox>
                            <AboutIcoLine />
                            <AboutIcoBox>
                                <AboutIcoImg className='ico2' >
                                    <img src='./img/about_02.png' alt='about_02'/>
                                </AboutIcoImg>
                                <AboutIcoSubTitle>프랜차이즈에 적합한</AboutIcoSubTitle>
                                <AboutIcoTitle>브랜드 디자인</AboutIcoTitle>
                                <AboutIcoDesc>
                                    <p>본점 매장의 포인트를 살려 프랜차이즈에</p>
                                    <p>적합하게 브랜드를 리디자인 합니다.</p>
                                    <p>아울러, 홈페이지와 인쇄물 등의 디자인을 위해</p>
                                    <p>사진촬영 등 부가 서비스도 함께 진행합니다.</p><br/>
                                    <p>리브랜더는 디자인의 모든 저작권을</p>
                                    <p>가맹본부에 귀속시킵니다.</p>
                                    <p>정직한 서비스를 약속드립니다.</p>
                                </AboutIcoDesc>
                            </AboutIcoBox>
                            <AboutIcoLine />
                            <AboutIcoBox>
                                <AboutIcoImg>
                                    <img src='./img/about_03.png' alt='about_03' />
                                </AboutIcoImg>
                                <AboutIcoSubTitle>각종 홍보 채널을 통한</AboutIcoSubTitle>
                                <AboutIcoTitle>가맹점 유치 마케팅</AboutIcoTitle>
                                <AboutIcoDesc>
                                    <p>가맹점 유치를 위해</p>
                                    <p>SNS등 다양한 채널을 통해</p>
                                    <p>마케팅을 진행합니다.</p><br/>
                                    <p>상담전화도 대행합니다.</p>
                                    <p>마음 편히 장사에만 집중하세요</p>
                                </AboutIcoDesc>
                            </AboutIcoBox>
                        </AboutIcoBoxWrap>
                    </AboutWeWrap>
                </AboutWe>
            </AboutLayout>
            <VideoContainer>
                <VideoWrap>
                    <Video src='./../video/company_video.mp4' type="video/mp4" loop autoPlay muted playsInline />
                </VideoWrap>
                <VideoTxtWrap>
                    <VideoSubTitle>가맹사업에</VideoSubTitle>
                    <VideoTxtTitle>왕도는 없다</VideoTxtTitle>
                    <VideoTxt>
                        <p>가맹사업을 확장하는 것에 있어서 왕도는 없다고 생각합니다</p><br/>
                        <p>상담문의를 많이 받고 진정성 있게 상담을 해드리면</p>
                        <p>그것이 최고의 방법이라고 생각합니다</p><br/>
                        <p>음식 맛 좋고, 브랜드 컨셉이 확실하여,<span> 손님들이 줄 서기 시작하면,</span></p>
                        <p>자연스럽게 가맹상담이 증가하는 일을 <span>항상 경험해왔습니다.</span></p><br/>
                        <p>리브랜더는 브랜딩을 하겠습니다</p>
                        <p>대표님께서는 장사와 음식 맛에 대한 <span>자부심만 지켜주십시오</span></p><br/>
                        <p>‘가고 싶은 식당'을 만드는 것에 집중하면</p>
                        <p>세상은 그 노력에 화답할 것입니다.</p><br/>
                        <p>가맹사업의 시작은 리브랜더와 함께</p>
                    </VideoTxt>
                    <VideoLogo>
                        <img src='./img/company_logo.png' alt='logo' />
                    </VideoLogo>
                </VideoTxtWrap>
            </VideoContainer>
            <Footer />
        </>
    )
}

export default CompanyIntro;
