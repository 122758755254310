import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { motion } from 'framer-motion';
import useMobile from '../util/useMobile';

const MainSectionImg = styled.div`
    position: relative;
    width: 100%;
    height: 100svh;
    background: ${(props)=> `url(${props.$bgUrl}) no-repeat center`};
    background-size: cover;
`

const MainSectionTxtWrap = styled.div`
    position: absolute;
    top: 49%;
    right: 21%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width:1024px) {
        padding: 0px 20px;
        top: 45.5%;
        left: 0;
        right: 0;
    }
`

const MainSectionTxt = styled.div`
    font-size: 1.125rem;
    line-height: 2;
    font-weight: 400;
    &.port {
        font-size: 3.75rem;
        line-height: 1;
        font-weight: 700;
        @media (max-width:1024px) {
            font-size: 2.25rem;
        }
    }
    @media (max-width:1024px) {
        font-size: 0.875rem;
        line-height: 1.5;
        span {
            display: block;
        }
    }
`

export const ScrollDownBox = styled.div`
    position: absolute;
    right: 40px;
    bottom: 50px;
    width: 100px;
    height: 100px;
    @media (max-width:1024px) {
        width: 30px;
        height: 30px;
        bottom: 20px;
        left: 20px;
    }
    img {
        width: 100%;
    }
`

const MainMoveTxtContainer = styled.div`
    padding: 100px 60px;
    @media (max-width:1024px) {
        padding: 200px 20px;
    }
`

const MainMoveTxtWrap = styled.div`
    max-width: 1460px;
    margin: 0 auto;
    overflow: hidden;
`

const MainMoveTxt1 = styled(motion.div)`
    font-size: 8.75rem;
    letter-spacing: -1.4px;
    line-height: 1.2;
    @media (max-width:1024px) {
        font-size: 3.75rem;
        letter-spacing: -0.6px;
    }
`

const MainMoveTxt2 = styled(motion.div)`
    position: relative;
    font-size: 8.75rem;
    letter-spacing: -1.4px;
    line-height: 1.2;
    text-align: right;
    overflow: hidden;
    @media (max-width:1024px) {
        font-size: 3.75rem;
        text-align: left;
        letter-spacing: -0.6px;
    }
`

const TxtBlackBox = styled.span`
    display: inline-block;
    width: 14px;
    height: 14px;
    background-color: #000;
    margin-left: 3px;
    @media (max-width: 1024px) {
        width: 6px;
        height: 6px;
    }
`

const MainStickyContainer = styled.div`
    position: relative;
    height: 400vh;
`

const MainStickyOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3;
    opacity: ${(props)=>props.$isDarkened ? '1' : '0'};
    transition: opacity 0.5s ease;
    pointer-events: none;
`

const StoreWrap = styled.div`
    position: sticky;
    top: 0;
    height: 100vh;
`

const StoreImg = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
    img {
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
        transform: ${(props)=>props.$isDarkened ? 'scale(1)' : 'scale(1.05)'};
        transition: all 0.4s ease;
        width: 100%;
        height: 100%;
    }
`

const MainStoreBox = styled.div`
    width: 761px;
    height: 190px;
    border-radius: 10px;
    background-color: #fff;
    position: absolute;
    top: 50px;
    left: 120px;
    display: flex;
    padding: 30px;
    justify-content: space-between;
    transform: ${(props)=>props.$isDarkened && 'scale(0.95)'};
    transition: all 0.4s ease;
    @media (max-width:1024px) {
        top: 80px;
        left: 5%;
        max-width: 320px;
        flex-direction: column;
        gap: 16px;
        height: auto;
        padding: 20px;
        width: 100%;
    }
    @media (max-width:359px) {
        left: 50%;
        transform: scale(0.95) translate(-50%,0);
    }
`

const StoreBoxTxtLeft = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const StoreBoxTitle = styled.div`
    color: #727272;
    font-size: 1rem;
    line-height: 1;
    font-weight: 500;
    @media (max-width:1024px) {
        font-size: 0.75rem;
    }
`

const StoreBoxName = styled.div`
    font-size: 2.25rem;
    line-height: 1;
    font-weight: 700;
    color: #000000;
    &.two {
        line-height: 1.5;
    }
    @media (max-width:1024px) {
        font-size: 1.5rem;
    }
`

const StoreBoxTxtRight = styled.div`
    max-width: 384px;
    width: 100%;
    @media (max-width:1024px) {
        width: 210px;
    }
`

const StoreBoxDesc = styled.div`
    font-size: 1.125rem;
    font-weight: 400;
    @media (max-width:1024px) {
        font-size: 0.875rem;
    }
`

const StoreBoxArrow = styled.div`
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #000;
    cursor: pointer;
    position: absolute;
    right: 30px;
    bottom: 30px;
    z-index: 2;
    &:hover {
        background-color: #404040;
    }
    img {
        width: 19.35px;
        height: 17.05px;
    }
    @media (max-width:1024px) {
        width: 36px;
        height: 36px;
        right: 20px;
        bottom: 20px;
    }
`

const MainContact = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 60px;
    @media (max-width:1024px) {
        padding: 0px 20px;
    }
`

const MainContactTxtWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 24px;
    max-width: 597px;
    width: 100%;
    @media (max-width:1024px) {
        max-width: 320px;
        width: 100%;
    }
`

const MainContactTxtTitle = styled.div`
    position: relative;
    font-size: 7.813rem;
    line-height: 1;
    letter-spacing: -1.25px;
    &::after {
        position: absolute;
        content: "";
        bottom: 19px;
        width: 14px;
        height: 14px;
        background-color: #000;
        margin-left: 9px;
        @media (max-width:1024px) {
            margin-left: 7.45px;
            width: 6px;
            height: 6px;
            bottom: 9px;
        }
    }
    @media (max-width:1024px) {
        font-size: 3.75rem;
        letter-spacing: -0.6px;
    }
`

const MainContactTxtSubTitle = styled.div`
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
    @media (max-width:1024px) {
        font-size: 1.5rem;
        span {
            display: block;
        }
    }
`

export const CounselBtn = styled.div`
    padding: 21px 0px;
    max-width: 484px;
    width: 100%;
    background-color: #000;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    cursor: pointer;
    &:hover {
        background-color: #404040;
    }
    p {
        color: #fff;
        font-size: 1.375rem;
        font-weight: 700;
        @media (max-width:1024px) {
            font-size: 1rem;
        }
    }
    @media (max-width:1024px) {
        padding: 10px 0px;
        min-width: 88px;
        width: 100%;
        height: 48px;
        border-radius: 6px;
    }
`

function Main() {
    
    const navigate = useNavigate();
    // 모바일
    const isMobile = useMobile(768);
    // 헤더 숨김 유무
    const [headerVisible, setHeaderVisible] = useState(true);
    // 메인 두번째 섹션 문장 상태
    const [isVisible, setIsVisible] = useState(false);
    // 어두워지는 효과 상태
    const [isDarkened, setIsDarkened] = useState([false, false, false, false]);
    // 현재 스크롤 값 저장
    const scrollRef = useRef(0);
    // 메인 첫번째 섹션
    const section1Ref = useRef(null);
    // 메인 두번째 섹션 (왼쪽에서 오른쪽으로 글자 나오는 효과)
    const section2Ref = useRef(null);
    // 메인 세번째 섹션 (배경 pin)
    const section3Ref = useRef(null);
    // 메인 세번째 섹션 가게 2
    const mainSection2Ref = useRef(null);
    // 메인 세번째 섹션 가게 3
    const mainSection3Ref = useRef(null);
    // 메인 세번째 섹션 가게 4
    const mainSection4Ref = useRef(null);

    // 모바일 환경 첫번째 섹션 조금이라도 벗어나면 두번째 섹션 문장 상태 변경
    useEffect(()=> {
        const section1Scroll = () => {
            if(section1Ref.current) {
                const rect = section1Ref.current.getBoundingClientRect();
                const inViewport = rect.top < 0;
                if(inViewport && isMobile) {
                    setIsVisible(true);
                }
            }
        };

        window.addEventListener('scroll', section1Scroll);

        return () => {
            window.removeEventListener('scroll', section1Scroll);
        };
    },[isVisible]);

    // 메인 두번째 섹션 진입 여부 
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            },
            {
                threshold:  0.1, 
            }
        );

        if (section2Ref.current) {
            observer.observe(section2Ref.current);
        }

        return () => {
            if (section2Ref.current) {
                observer.unobserve(section2Ref.current);
            }
        };
    }, []);

    // 메인 세번째 섹션 도착시 스크롤 방향에 따라 헤더 숨김 유무
    useEffect(() => {
        const handleScroll = () => {
            if (section3Ref.current) {
                const rect = section3Ref.current.getBoundingClientRect();
                const inViewport = rect.bottom <= rect.height && rect.height > -rect.y;
    
                if (rect.height < -rect.y) {
                    setHeaderVisible(true); 
                }else if (inViewport) {
                    if (scrollRef.current > window.scrollY) {
                        setHeaderVisible(true);
                    } else {
                        setHeaderVisible(false); 
                    }
                }
            }
            scrollRef.current = window.scrollY; 
        };
    
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        
    }, []);
    
    // 메인 세번째 영역 박스 화살표 핸들러
    const handleArrow = (target) => {
        navigate('/portfolio', { state: { target: target } });
    };

    // 메인 세번째 영역 다음 사진 나오면 어두워지는 효과
    useEffect(() => {
        const sectionsRefs = [mainSection2Ref, mainSection3Ref, mainSection4Ref];
    
        const observers = sectionsRefs.map((ref, index) => {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    setIsDarkened(prevState => {
                        const newState = [...prevState];
                        newState[index] = entry.isIntersecting;
                        return newState;
                    });
                },
                {
                    root: null,
                    threshold: isMobile ? 0.3 : 0.01,
                }
            );
            if (ref.current) observer.observe(ref.current);
            return observer;
        });
    
        return () => {
            observers.forEach((observer, index) => {
                const ref = sectionsRefs[index];
                if (ref.current) observer.unobserve(ref.current);
            });
        };
    }, [isMobile]);

    return (
        <>
            <Header isVisible={headerVisible}/>
            <MainSectionImg $bgUrl={isMobile ? './../img/main_section_mobile.png' : './../img/main_section.png'} ref={section1Ref}>
                <MainSectionTxtWrap>
                    <MainSectionTxt className='port'>포트폴리오</MainSectionTxt>
                    <MainSectionTxt>리브랜더와 함께<span> 나만의 브랜드를 완성해보세요</span></MainSectionTxt>
                </MainSectionTxtWrap>
                <ScrollDownBox>
                    <img src={isMobile ? './../img/scroll_down_mobile.png' : './../img/scroll_down.png'} alt='scroll_down' />
                </ScrollDownBox>
            </MainSectionImg>
            <MainMoveTxtContainer>
                <MainMoveTxtWrap ref={section2Ref}>
                    <MainMoveTxt1
                        initial={{ transform: 'translate3d(-100%, 0, 0)' }}
                        animate={{ transform: isVisible ? 'translate3d(0, 0, 0)' : 'translate3d(-100%, 0, 0)' }}
                        transition={{ duration: isMobile ? 0.5 : 1 }} 
                        className='raleway'>We make your</MainMoveTxt1>
                    <MainMoveTxt2
                        initial={{ transform: 'translate3d(-100%, 0, 0)' }}
                        animate={{ transform: isVisible ? 'translate3d(0, 0, 0)' : 'translate3d(-100%, 0, 0)' }}
                        transition={{ duration: isMobile ? 0.5 : 1, delay: isMobile ? 0.15 : 0.5 }}
                        className='raleway'>dreams come true<TxtBlackBox /></MainMoveTxt2>
                </MainMoveTxtWrap>
            </MainMoveTxtContainer>
            <MainStickyContainer ref={section3Ref}>
                <StoreWrap>
                    <MainStickyOverlay $isDarkened={isDarkened[0]} />
                    <MainStoreBox $isDarkened={isDarkened[0]}>
                        <StoreBoxTxtLeft>
                            <StoreBoxTitle>베트남 스트릿푸드 전문점</StoreBoxTitle>
                            <StoreBoxName>까몬(CAMON)</StoreBoxName>
                        </StoreBoxTxtLeft>
                        <StoreBoxTxtRight>
                            <StoreBoxDesc>베트남 현지 분위기를 그대로 재현하고 한국인의 입맛에 맞도록 베트남 요리를 재해석한 브랜드입니다.</StoreBoxDesc>
                        </StoreBoxTxtRight>
                        <StoreBoxArrow onClick={() => handleArrow('까몬')}>
                            <img src='./img/arrow.png' alt='arrow' />
                        </StoreBoxArrow>
                    </MainStoreBox>
                    <StoreImg $isDarkened={isDarkened[0]}>
                        <img src={isMobile ? './img/main_store1_mobile.png' : './img/main_store1.png'} />
                    </StoreImg>
                </StoreWrap>
                <StoreWrap ref={mainSection2Ref}>
                    <MainStickyOverlay $isDarkened={isDarkened[1]} />
                    <MainStoreBox $isDarkened={isDarkened[1]}>
                        <StoreBoxTxtLeft>
                            <StoreBoxTitle>일식 텐동 전문점</StoreBoxTitle>
                            <StoreBoxName>온센(ONSEN)</StoreBoxName>
                        </StoreBoxTxtLeft>
                        <StoreBoxTxtRight>
                            <StoreBoxDesc>백종원의 골목식당 출연으로 유명세를 얻은 식당으로 텐동의 국내 대중화를 이끈 브랜드입니다.</StoreBoxDesc>
                            <StoreBoxArrow onClick={() => handleArrow('온센')}>
                                <img src='./img/arrow.png' alt='arrow' />
                            </StoreBoxArrow>
                        </StoreBoxTxtRight>
                    </MainStoreBox>
                    <StoreImg $isDarkened={isDarkened[1]}>
                        <img src={isMobile ? './img/main_store2_mobile.png' : './img/main_store2.png'} />
                    </StoreImg>
                </StoreWrap>
                <StoreWrap ref={mainSection3Ref}>
                    <MainStickyOverlay $isDarkened={isDarkened[2]} />
                    <MainStoreBox $isDarkened={isDarkened[2]}>
                        <StoreBoxTxtLeft>
                            <StoreBoxTitle>노블 돈가스 전문점</StoreBoxTitle>
                            <StoreBoxName className='two'>인생돈가스<br />(人生のとんかつ)</StoreBoxName>
                        </StoreBoxTxtLeft>
                        <StoreBoxTxtRight>
                            <StoreBoxDesc>동탄 지역의 명물 돈가스로 한 자리에서 차곡차곡 브랜드를 쌓아올린 인생돈가스가 새로운 컨셉으로 찾아갑니다.</StoreBoxDesc>
                            <StoreBoxArrow onClick={() => handleArrow('인생돈가스')}>
                                <img src='./img/arrow.png' alt='arrow' />
                            </StoreBoxArrow>
                        </StoreBoxTxtRight>
                    </MainStoreBox>
                    <StoreImg $isDarkened={isDarkened[2]}>
                        <img src={isMobile ? './img/main_store3_mobile.png' : './img/main_store3.png'} />
                    </StoreImg>
                </StoreWrap>
                <StoreWrap ref={mainSection4Ref}>
                    <MainStickyOverlay $isDarkened={isDarkened[3]} />
                    <MainStoreBox $isDarkened={isDarkened[3]}>
                        <StoreBoxTxtLeft>
                            <StoreBoxTitle>멕시코 타코 전문점</StoreBoxTitle>
                            <StoreBoxName className='two'>맘모스타코<br />(MAMMOTH TACO)</StoreBoxName>
                        </StoreBoxTxtLeft>
                        <StoreBoxTxtRight>
                            <StoreBoxDesc>차고지 컨셉의 매장 디자인으로 눈길을 끌며, 로또·담배 소매점과 콜라보 가능한 샵인샵 브랜드 입니다.</StoreBoxDesc>
                            <StoreBoxArrow onClick={() => handleArrow('맘모스타코')}>
                                <img src='./img/arrow.png' alt='arrow' />
                            </StoreBoxArrow>
                        </StoreBoxTxtRight>
                    </MainStoreBox>
                    <StoreImg $isDarkened={isDarkened[3]}>
                        <img src={isMobile ? './img/main_store4_mobile.png' : './img/main_store4.png'} />
                    </StoreImg>
                </StoreWrap>
            </MainStickyContainer>
            <MainContact>
                <MainContactTxtWrap>
                    <MainContactTxtTitle className='raleway'>Contact</MainContactTxtTitle>
                    <MainContactTxtSubTitle>리:브랜더 맞춤형 <span>인큐베이팅 서비스로</span> 상상하던 꿈을 실현해보세요</MainContactTxtSubTitle>
                </MainContactTxtWrap>
                <CounselBtn onClick={()=>{navigate("/contact")}}>
                    <p>상담 신청하기</p>
                </CounselBtn>
            </MainContact>
            <Footer />
        </>
    )
}

export default Main