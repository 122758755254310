import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

const Container = styled.div`
    width: 100%;
    height: 108px;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    background-color: ${(props) => props.$headerColor};
    transition: all 0.2s;
    padding: 0px 40px;
    z-index: 50;
    visibility: ${props => props.$visible ? 'visible' : 'hidden'};
    @media (max-width:1024px) {
        justify-content: space-between;
        padding: 0px 20px;
        height: 60px;
    }
`

const NavListWrap = styled.ul`
    display: flex;
    align-items: center;
    gap: 40px;
    line-height: 1;
    @media (max-width:1024px) {
        display: none;
    }
`

const NavList = styled.li`
    display: flex;
    align-items: flex-end;
    font-size: 1rem;
    font-weight: 500;
    height: 20px;
    cursor: pointer;
`

const MnavHamburger = styled.div`
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: fixed;
    top: 15px;
    right: 20px;
    z-index: 10;
    @media (min-width:1025px){
        display: none;
    }
    img {
        width: 100%;
    }
`

const MnavWrap = styled.div`
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    padding: 0px 20px;
    align-items: center;
    flex-direction: column;
    transform: ${(props)=>props.$isOpen ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform 0.4s; 
    @media (min-width:1025px){
        display: none;
    }
`

const MnavListWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 100%;
    gap: 30px;
`

const MnavList = styled.div`
    font-size: 1.875rem;
    font-weight: 800;
    cursor: pointer;
`

const MnavBottom = styled.div`
    width: 100%;
    border-top: 1px solid #B5B5B5;
    padding: 30px 0px 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const MnavBottomTxt = styled.div`
    font-size: 0.875rem;
    font-weight: 500;
`

const NavListBadge = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 17.87px;
    border-radius: 5px;
    padding: 2.34px 6px 1.63px;
    background-color: #FF4200;
    font-size: 0.700rem;
    color: #FFFFFF;
    margin-left: 6px;
    font-weight: 600;
    line-height: 1;
    @media (max-width:1024px) {
        margin: 6px 0px 0px 10px;
    }
`

const NavEmpty = styled.div`
    width: 140px;
    @media (max-width:1024px) {
        display: none;
    }
`

const Logo = styled.div`
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img{
        width: 100%;
    }
`

function Header({isVisible}) {
    
    const navigate = useNavigate();
    const { pathname } = useLocation();
    // 모바일 메뉴 상태
    const [isOpen , setIsOpen] = useState(false);
    // 헤더 배경색 상태
    const [headerColor,setHeaderColor] = useState("transparent");

    // 50 내려가면 헤더 배경색 흰색
    useEffect(() => {
        const handleScroll = () => {
          const scrollY = window.scrollY || document.documentElement.scrollTop;
          setHeaderColor(scrollY < 50 ? "transparent" : "#fff");
        };
    
        window.addEventListener("scroll", handleScroll);

        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);

    // 모바일 메뉴 나오면 스크롤 없앰
    useEffect(()=>{
        isOpen ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto';
    },[isOpen]);

    // 주소 이동시 상단
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[pathname]);

    // 화면 크기 변경 시 모바일 메뉴 닫기
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 1024) {
                setIsOpen(false);
            }
        };

        window.addEventListener("resize", handleResize);
        
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            <Container $headerColor={headerColor} $visible={isVisible}>
                <Logo onClick={()=>{navigate("/")}}>
                    <img src='./img/logo.png' alt='logo' />
                </Logo>
                <NavEmpty />
                <NavListWrap>
                    <NavList onClick={()=>{navigate("/")}}>포트폴리오</NavList>
                    <NavList onClick={()=>{navigate("/service")}}>서비스 소개</NavList>
                    <NavList onClick={()=>{navigate("/company")}}>회사 소개</NavList>
                    <NavList onClick={()=>{navigate("/contact")}}>상담신청<NavListBadge>EASY</NavListBadge></NavList>
                </NavListWrap>
                <MnavHamburger onClick={()=>{setIsOpen(!isOpen)}}>
                    <img src={isOpen ? './img/close.png' : './img/hamburger.png'} alt='hamburger' />
                </MnavHamburger>
                <MnavWrap $isOpen={isOpen}>
                    <MnavListWrap>
                        <MnavList onClick={()=>{navigate("/")}}>포트폴리오</MnavList>
                        <MnavList onClick={()=>{navigate("/service")}}>서비스 소개</MnavList>
                        <MnavList onClick={()=>{navigate("/company")}}>회사 소개</MnavList>
                        <MnavList onClick={()=>{navigate("/contact")}} style={{display:'flex'}}>상담신청<NavListBadge>EASY</NavListBadge></MnavList>
                    </MnavListWrap>
                    <MnavBottom>
                        <MnavBottomTxt>02 - 512 - 7776</MnavBottomTxt>
                        <MnavBottomTxt>서울특별시 강남구 도산대로28길 26,2층 (논현동, 수환빌딩)</MnavBottomTxt>
                    </MnavBottom>
                </MnavWrap>
            </Container>
        </>
    )
}

export default Header