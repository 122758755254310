import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import styled from 'styled-components';
import { md } from '../styled';


const FullScreenPopup = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    height: 100dvh;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    z-index: 51;
    /* pointer-events: none; */
    touch-action: none;
`;

const SliderContainer = styled.div`
    position: relative;
    max-width: 57.2916%;
    max-height: 68.6475%;

    ${md`
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
    `}
`;

const SliderItem = styled.div`
    position: relative;
    min-height: 670px;

    ${md`
        width: 100%;
        height: 100%;
        min-height: 100dvh;
        display: flex; align-items: center;
    `}
`;

const SliderItemImg = styled.img`
  max-width: 100%;
  max-height: 670px;
  margin: 0 auto;
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);

  ${md`
      position: static;
      display: flex;
      align-items: center;
      transform: translate(0, 0);
      max-height: 40%;
  `}
`;

const IndexIndicator = styled.div`
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #fff;
    margin-top: 30px;


    ${md`
       position: absolute;
       left: 50%;
       bottom: 30px;
       transform: translateX(-50%);
       z-index: 1;
    `}
`;

const StyledArrows = styled.div`
    position: relative;
    width: 100px; height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;

    ${md`
        display: none;
    `}
`;

const StyledArrowsImg = styled.img`
    width: 100px; height: 100px;
`;

const CloseBtn = styled.img`
    display: block;
    color: #fff;  
    position: absolute;
    top: 100px; left: 0;
    transform: translateX(-100%);
    cursor: pointer;
    z-index: 49;
    width: 30px; height: 30px;

    ${md`
      display: none;
    `}
    
`;

const CloseBtnMobile = styled.img`

    width: 30px; height: 30px;
    display: none;
    color: #fff;  
    position: absolute;
    top: 20px; right: 20px;
    cursor: pointer;
    z-index: 49;

    ${md`
      display: block;
    `}
    
`;

export const FullScreenSlider = (props) => {
    const { data, onClose, firstIndex } = props;
    const [index, setIndex] = useState(firstIndex || 0);

    const swiperRef = useRef();

    const handleSlideChange = (swiper) => {
        setIndex(swiper.activeIndex);
    };

    const SlideButtonPrev = () => {
      return <StyledArrows onClick={() => swiperRef.current.slidePrev()}>
                {index === 0 ? (
                <StyledArrowsImg className='arrows-img' src='./img/arrow_left_disabled.png' />
              ) : (
                <StyledArrowsImg className='arrows-img' src='./img/arrow_left.png' />
              )}
            </StyledArrows>
    }

    const SlideButtonNext = (props) => {
      return <StyledArrows onClick={() => swiperRef.current.slideNext()}>
                {index+1 === data.length ? (
                <StyledArrowsImg className='arrows-img' src='./img/arrow_right_disabled.png' />
              ) : (
                <StyledArrowsImg className='arrows-img' src='./img/arrow_right.png' />
              )}
              {props.children} {/* close 버튼 들어올 공간 */}
            </StyledArrows>
    }

    return (
        <FullScreenPopup className='full-screen-popup'>

            <SlideButtonPrev /> {/* prev 버튼 */}

            <SliderContainer className='styled-slider-container' {...props}>
                
                <Swiper
                    initialSlide={firstIndex || 0}
                    onSlideChange={handleSlideChange}
                    spaceBetween={50}
                    slidesPerView={1}
                    loop={false}
                    style={{position: 'relative'}}
                    onSwiper={(swiper) => (swiperRef.current = swiper)}

                
                >
                  
                    {data.map((slide, idx) => (
                        <SwiperSlide key={'slide' + idx}>
                            <SliderItem className='slider-item'>
                                <SliderItemImg
                                    className='asdasd'
                                    src={slide.src}
                                />
                            </SliderItem>
                        </SwiperSlide>
                    ))}

                    {/* <SlidePrevButton/> <SlideNextButton/> */}
                    
                </Swiper>

                <IndexIndicator className='index-indicator'>
                    {index + 1} / {data.length}
                </IndexIndicator>
            </SliderContainer>

            <SlideButtonNext> 
              <CloseBtn className='close-btn' src='/img/close_white.png' onClick={() => onClose && onClose()}/>
            </SlideButtonNext> {/* next 버튼 */}

            <CloseBtnMobile className='close-btn' src='/img/close_white.png' onClick={() => onClose && onClose()}/>

        </FullScreenPopup>
    );
};

