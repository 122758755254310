import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const FooterContainer = styled.div`
    width: 100%;
    background-color: #000;
    padding: 0px 40px 0px 41px;
    color: #fff;
    position: relative;
    @media (max-width:1024px) {
        flex-wrap: wrap;
        padding: 0px 20px;
    }
`

const FooterTopBtn = styled.div`
    position: absolute;
    top: 70px;
    right: 40px;
    width: 100px;
    height: 100px;
    cursor: pointer;
    @media (max-width:1024px) {
        display: none;
    }
    img {
        width: 100%;
    }
`

const FooterTop = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 70px 0px;
    border-bottom: 1px solid #434343;
    @media (max-width:1024px) {
        flex-wrap: wrap;
        padding: 50px 0px;
    }
`

const FooterLeft = styled.div`
    height: 204px;
    flex-basis: 50%;
    @media (max-width:1024px) {
        flex-basis: 100%;
        padding-top: 50px;
        height: auto;
    }
`

const FooterMenuTitle = styled.div`
    font-size: 1.5rem;
    line-height: 1;
    font-weight: 700;
    @media (max-width: 1024px) {
        font-size: 0.875rem;
        margin-left: 1px;
    }
`

const FooterMenuListWrap = styled.ul`
    display: flex;
    align-items: center;
    gap: 40px;
    margin-top: 30px;
    line-height: 1;
    @media (max-width:1024px) {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;
        gap: 16px;
    }
`

const FooterMenuList = styled.li`
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    @media (max-width:1024px) {
        font-size: 0.75rem;
    }
`

const FooterRight = styled.div`
    height: 204px;
    flex-basis: 50%;
    @media (max-width:1024px) {
        flex-basis: 100%;
        padding-top: 50px;
        height: auto;
    }
`

const FooterContactsTitle = styled.div`
    font-size: 1.5rem;
    line-height: 1;
    @media (max-width:1024px) {
        font-size: 0.875rem;
        &.ralewayB {
            margin-left: 1px;
        }
    }
`

const FooterTell = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
    p{
        line-height: 1;
        font-size: 1rem;
        font-weight: 500;
        @media (max-width:1024px) {
            font-size: 0.75rem;
        }
    }
    @media (max-width:1024px) {
        margin-top: 16px;
    }
`

const FooterMobAddress = styled(FooterTell)`
    margin-top: 20px;
    align-items: center;
    gap: 0;
    p {
        cursor: pointer;
        line-height: normal;
    }
    @media (max-width:354px) {
        align-items: flex-start;
        p {
            line-height: 1.5;
        }
    }
    @media (min-width:1025px) {
        display: none;
    }
`

const FooterAddress = styled(FooterTell)`
    margin-top: 20px;
    p {
        line-height: normal;
    }
    @media (max-width:1024px) {
        margin-top: 16px;
        display: none;
    }
`

const FooterInsta = styled(FooterTell)`
    margin-top: 20px;
    @media (max-width:1024px) {
        margin-top: 16px;
    }
`

const FooterLine = styled.div`
    width: 1px;
    height: 16px;
    background-color: #fff;
    @media (max-width:1024px) {
        height: 14px;
    }
`

const FooterBottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const FooterBottomLogo = styled.div`
    flex-basis: 50%;
    max-width: 543px;
    img {
        width: 100%;
    }
    @media (max-width:1024px) {
        display: none;
    }
`

const FooterMobileLogo = styled.div`
    max-width: 223px;
    img{
        width: 100%;
    }
    @media (min-width:1025px) {
        display: none;
    }
`

const FooterBottomRight = styled.div`
    flex-basis: 50%;
    color: #939393;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.625rem;
    padding: 36px 0px 56px 0px;
    @media (max-width:1024px) {
        flex-basis: 100%;
        font-size: 0.75rem;
        line-height: 1.5rem;
        padding: 30px 0px 70px 0px;
    }
`

const FooterMapContainer = styled.div`
    display: flex;
    align-items: flex-end;
    p {
        cursor: pointer;
    }
`

const FooterMapWrap = styled.div`
    width: 24px;
    height: 24px;
    cursor: pointer;
    @media (max-width:1024px) {
        width: 20px;
        height: 20px;
    }
    img {
        width: 100%;
    }
`

function Footer() {

    const navigate = useNavigate();
    // 최상단 올라가는 버튼
    const MoveToTop = () =>{
        window.scrollTo({
            top:0, behavior:'smooth'
        })
    };

    return (
        <>
            <FooterContainer>
                <FooterTop>
                    <FooterMobileLogo>
                        <img src='./../img/logo_text_white.png' alt='logo_text_white' />
                    </FooterMobileLogo>
                    <FooterLeft>
                        <FooterMenuTitle className='ralewayB'>MENU</FooterMenuTitle>
                        <FooterMenuListWrap>
                            <FooterMenuList onClick={()=>{navigate("/")}}>포트폴리오</FooterMenuList>
                            <FooterMenuList onClick={()=>{navigate("/service")}}>서비스 소개</FooterMenuList>
                            <FooterMenuList onClick={()=>{navigate("/company")}}>회사 소개</FooterMenuList>
                            <FooterMenuList onClick={()=>{navigate("/contact")}}>상담신청</FooterMenuList>
                        </FooterMenuListWrap>
                    </FooterLeft>
                    <FooterRight>
                        <FooterContactsTitle className='ralewayB'>CONTACT</FooterContactsTitle>
                        <FooterMobAddress onClick={()=>{window.open("https://naver.me/xyT7OY5q","_blank")}}>
                            <FooterMapWrap>
                                <img src='./../img/map_ico.png' alt="map_ico" />
                            </FooterMapWrap>
                            <p>서울특별시 강남구 도산대로28길 26,2층 (논현동, 수환빌딩)</p>
                        </FooterMobAddress>
                        <FooterTell>
                            <p>Tel</p>
                            <FooterLine />
                            <p>02 - 512 - 7776</p>
                        </FooterTell>
                        <FooterAddress>
                            <p>Address</p>
                            <FooterLine />
                            <FooterMapContainer onClick={()=>{window.open("https://naver.me/xyT7OY5q","_blank")}}>
                                <FooterMapWrap>
                                    <img src='./../img/map_ico.png' alt="map_ico" />
                                </FooterMapWrap>
                                <p>서울특별시 강남구 도산대로28길 26,2층 (논현동, 수환빌딩)</p>
                            </FooterMapContainer>
                        </FooterAddress>
                        <FooterInsta>
                            <p>E-mail</p>
                            <FooterLine />
                            <p>rebrander@rebrander.kr</p>
                        </FooterInsta>
                        <FooterInsta>
                            <p>Instagram</p>
                            <FooterLine />
                            <p style={{cursor:'pointer'}} onClick={()=>{window.open("https://www.instagram.com/rebrander_?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D","_blank")}}>@rebrander_</p>
                        </FooterInsta>
                    </FooterRight>
                </FooterTop>
                <FooterBottom>
                    <FooterBottomLogo>
                        <img src='./../img/logo_text_white.png' alt='logo_text_white' />
                    </FooterBottomLogo>
                    <FooterBottomRight>
                        <p>사업자명 : 주식회사 리브랜더 · 대표자 : 현창희</p>
                        <p>© 2024 All Rights Reserved.</p>
                    </FooterBottomRight>
                </FooterBottom>
                <FooterTopBtn onClick={MoveToTop}>
                    <img src='./img/gotop.png' />
                </FooterTopBtn>
            </FooterContainer>
        </>
    )
}

export default Footer