const korOnly = /^[가-힣]+$/; // 한글만
const engOnly = /^[a-zA-z]+$/; // 영문만
const engLower = /^[a-z]+$/; // 영문 소문자만
const engUpper = /^[A-Z]+$/; // 영문 대문자만
const korEng = /^[가-힣a-zA-Z]+$/; // 한글 + 영문만
const numberOnly = /^[0-9]+$/; // 숫자만

export const profitCheck = (value) => {
    const status = numberOnly.test(value);
    if (!status) {
        alert('매출액은 숫자로만 작성해주세요.')
    }
    return status
}

export const isRequired = (value) => {

    const status = value.trim() !== '';
    if (!status) {
        alert('빈 칸 없이 작성해주세요.')
    }
    return status;
}

export const isMinLength = (value, minLength) => {
    return value.length >= minLength;
}

export const pwCheck = (value) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d@$!%*?&\^{}[\]:;<>,.?\/#~_+\-=|`'"\\(){}[\]]{10,20}$/;
    // const message = '비밀번호는 10~20자 영문 대 소문자, 숫자, 특수문자를 사용하세요.';
    return regex.test(value);
}

export const pinPwCheck = (value) => {
    const regex = /^\d{6}$/;
    // const message = '6자리 숫자로 입력해 주세요.';
    return regex.test(value);
}

export const telCheck = (value) => {
    const regex =  /^[0-9]{9,11}$/;
    const status = regex.test(value)

    if (!status) {
        alert('9~11자리 전화번호를 입력해 주세요.')
    }

    return status;
}


