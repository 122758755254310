import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components';
import { breakpoint, lg, md } from '../styled';
import Header from '../layout/Header';
import { FullScreenSlider } from '../components/CustomSlider';
import Footer from '../layout/Footer';
import { useIsMobile } from '../util/hooks';
import { useLocation } from 'react-router-dom';

// 스크롤 라이브러리 (아이폰에서는 스크롤 안보임)
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

const RootBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background-color: #fff;
`;

const BasicContainer = styled.div`
    width: 100%;
    max-width: calc(1840px);
    padding: 0 40px;
    margin: 0 auto;

    ${lg`
        width: calc(100% - 120px);
        padding: 0;
    `}

    ${md`
        width: calc(100% - 40px);
        padding: 0;
    `}

`;

const PortfolioHead = styled.div`
    margin-top: 108px;
    padding: 100px 0;

    ${md`
        margin-top: 60px;
        height: calc(100svh - 60px); /* 헤더 빼기 */
        display: flex; justify-content: center;
        flex-direction: column;
    `}
`;

const PortfolioTitle = styled.p`
    font-size: 60px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 30px;

    ${md`
        font-size: 36px;
        line-height: 1.5;
    `}
`;

const PortfolioSubtitle = styled.p`
    font-size: 18px;
    font-weight: 400;
    line-height: 1;

    ${md`
        font-size: 14px;
        line-height: 1.5;
    `}
`;

const TabMenu = styled.ul`
    white-space: nowrap;


    padding-bottom: 19px;

`;

const ScrollableDiv = styled.div`

    display: block;

    .simplebar-vertical{ // 세로 스크롤 안보이기
        display: none;
    }
    .simplebar-scrollbar{
        background-color: black;
        height: 1px;
    }
    .simplebar-track{
        background-color: #B5B5B5;
        height: 1px;
    }

    ${md`
        display: block;

        .simplebar-scrollbar{
            height: 2px;
        }
        .simplebar-track{
            height: 2px;
        }
    `}
`;

const TabMenuItem = styled.li`
    display: inline-block;
    padding: 0 30px;
    font-size: 30px;
    font-weight: 800;
    line-height: 1;
    color: #B5B5B5;
    cursor: pointer;

    &:first-child{
        padding-left: 0;
    }

    ${md`
        font-size: 24px;
        padding: 0 20px;
    `}
`;

const Description = styled.div`
    padding: 50px 0;

    ${md`
        padding: 100px 0 50px 0
    `}
`;

const DescriptionTitle = styled.p`
    font-size: 24px;
    font-weight: 800;
    line-height: 1;
    margin-bottom: 16px;
`;

const DescriptionSubtitle = styled.p`
    font-size: 18px;
    font-weight: 400;
    line-height: 1;

    ${md`
        font-size: 14px;
        line-height: 1.5;
    `}
`;

const Gallary = styled.div`
    display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 11px;

    width: 100%;
    max-width: calc(1840px);
    padding: 0 40px;
    margin: 0 auto;
    margin-bottom: 300px;

    ${lg`
        width: calc(100% - 120px);
        padding: 0;
    `}

    ${md`
        width: 100%;
        display: flex; flex-direction: column;
        margin-bottom: 0;
        padding: 0;
    `}
`;

const GallaryImg = styled.img`
    width: 100%;
    cursor: pointer;
    object-fit: cover;
    aspect-ratio: 1 / 1;
`;

const portfolioData = {
    contents: [
        { 
            name: '까몬',
            title: '까몬(CAMON)',
            subTitle: '베트남 현지 분위기를 그대로 재현하고 한국인의 입맛에 맞도록 베트남 요리를 재해석한 브랜드입니다.',
            img: [
                {id: 1, src: "./img/portfolio/camon/camon_1.jpg", category: "까몬"},
                {id: 2,  src: "./img/portfolio/camon/camon_2.jpg", category: "까몬"},
                {id: 3,  src: "./img/portfolio/camon/camon_3.jpg", category: "까몬"},
                {id: 4,  src: "./img/portfolio/camon/camon_4.jpg", category: "까몬"},
                {id: 5,  src: "./img/portfolio/camon/camon_5.jpg", category: "까몬"},
                {id: 6,  src: "./img/portfolio/camon/camon_6.jpg", category: "까몬"},
                {id: 7,  src: "./img/portfolio/camon/camon_7.jpg", category: "까몬"},
                {id: 8,  src: "./img/portfolio/camon/camon_8.jpg", category: "까몬"},
                {id: 9,  src: "./img/portfolio/camon/camon_9.jpg", category: "까몬"},
            ]
        },
        {
            name: '온센',
            title: '온센(ONSEN)',
            subTitle: '백종원의 골목식당 출연으로 유명세를 얻은 식당으로 텐동의 국내 대중화를 이끈 브랜드입니다.',
            img: [
                {id: 1,  src: "./img/portfolio/onsen/onsen_1.jpg", category: "온센"},
                {id: 2,  src: "./img/portfolio/onsen/onsen_2.jpg", category: "온센"},
                {id: 3,  src: "./img/portfolio/onsen/onsen_3.jpg", category: "온센"},
                {id: 4,  src: "./img/portfolio/onsen/onsen_4.jpg", category: "온센"},
                {id: 5,  src: "./img/portfolio/onsen/onsen_5.jpg", category: "온센"},
                {id: 6,  src: "./img/portfolio/onsen/onsen_6.jpg", category: "온센"},
                {id: 7,  src: "./img/portfolio/onsen/onsen_7.jpg", category: "온센"},
                {id: 8,  src: "./img/portfolio/onsen/onsen_8.jpg", category: "온센"},
                {id: 9,  src: "./img/portfolio/onsen/onsen_9.jpg", category: "온센"},
                {id: 10,  src: "./img/portfolio/onsen/onsen_10.jpg", category: "온센"},
                {id: 11,  src: "./img/portfolio/onsen/onsen_11.jpg", category: "온센"},
                {id: 12,  src: "./img/portfolio/onsen/onsen_12.jpg", category: "온센"},
                {id: 13,  src: "./img/portfolio/onsen/onsen_13.jpg", category: "온센"},
                {id: 14,  src: "./img/portfolio/onsen/onsen_14.jpg", category: "온센"},
                {id: 15,  src: "./img/portfolio/onsen/onsen_15.jpg", category: "온센"},
                {id: 16,  src: "./img/portfolio/onsen/onsen_16.jpg", category: "온센"},
                {id: 17,  src: "./img/portfolio/onsen/onsen_17.jpg", category: "온센"}
            ]
        },
        {
            name: '인생돈가스',
            title: '인생돈가스(人生のとんかつ)',
            subTitle: '동탄 지역의 명물 돈가스로 한 자리에서 차곡차곡 브랜드를 쌓아올린 인생돈가스가 새로운 컨셉으로 찾아갑니다.',
            img: [
                {id: 1,  src: "./img/portfolio/insaengdongaseu/insaeng_1.jpg", category: "인생돈가스"},
                {id: 2,  src: "./img/portfolio/insaengdongaseu/insaeng_2.jpg", category: "인생돈가스"},
                {id: 3,  src: "./img/portfolio/insaengdongaseu/insaeng_3.jpg", category: "인생돈가스"},
                {id: 4,  src: "./img/portfolio/insaengdongaseu/insaeng_4.jpg", category: "인생돈가스"},
                {id: 5,  src: "./img/portfolio/insaengdongaseu/insaeng_5.jpg", category: "인생돈가스"},
                {id: 6,  src: "./img/portfolio/insaengdongaseu/insaeng_6.jpg", category: "인생돈가스"},
                {id: 7,  src: "./img/portfolio/insaengdongaseu/insaeng_7.jpg", category: "인생돈가스"},
                {id: 8,  src: "./img/portfolio/insaengdongaseu/insaeng_8.jpg", category: "인생돈가스"},
                {id: 9,  src: "./img/portfolio/insaengdongaseu/insaeng_9.jpg", category: "인생돈가스"},
                {id: 10,  src: "./img/portfolio/insaengdongaseu/insaeng_10.jpg", category: "인생돈가스"},
                {id: 11,  src: "./img/portfolio/insaengdongaseu/insaeng_11.jpg", category: "인생돈가스"},
                {id: 12, src: "./img/portfolio/insaengdongaseu/insaeng_12.jpg", category: "인생돈가스"},
                {id: 13,  src: "./img/portfolio/insaengdongaseu/insaeng_13.jpg", category: "인생돈가스"},
                {id: 14,  src: "./img/portfolio/insaengdongaseu/insaeng_14.jpg", category: "인생돈가스"},
                {id: 15,  src: "./img/portfolio/insaengdongaseu/insaeng_15.jpg", category: "인생돈가스"},
                {id: 16, src: "./img/portfolio/insaengdongaseu/insaeng_16.jpg", category: "인생돈가스"},
                {id: 17, src: "./img/portfolio/insaengdongaseu/insaeng_17.jpg", category: "인생돈가스"},
                {id: 18, src: "./img/portfolio/insaengdongaseu/insaeng_18.jpg", category: "인생돈가스"},
                {id: 19, src: "./img/portfolio/insaengdongaseu/insaeng_19.jpg", category: "인생돈가스"},
                {id: 20, src: "./img/portfolio/insaengdongaseu/insaeng_20.jpg", category: "인생돈가스"},
                {id: 21, src: "./img/portfolio/insaengdongaseu/insaeng_21.jpg", category: "인생돈가스"},
                {id: 22, src: "./img/portfolio/insaengdongaseu/insaeng_22.jpg", category: "인생돈가스"},
                {id: 23, src: "./img/portfolio/insaengdongaseu/insaeng_23.jpg", category: "인생돈가스"},
                {id: 24, src: "./img/portfolio/insaengdongaseu/insaeng_24.jpg", category: "인생돈가스"},
                {id: 25, src: "./img/portfolio/insaengdongaseu/insaeng_25.jpg", category: "인생돈가스"},
                {id: 26, src: "./img/portfolio/insaengdongaseu/insaeng_26.jpg", category: "인생돈가스"},
                {id: 27, src: "./img/portfolio/insaengdongaseu/insaeng_27.jpg", category: "인생돈가스"},
                {id: 28, src: "./img/portfolio/insaengdongaseu/insaeng_28.jpg", category: "인생돈가스"},
            ]   
        },
        {
            name: '맘모스타코',
            title: '맘모스타코(MAMMOTH TACO)',
            subTitle: '차고지 컨셉의 매장 디자인으로 눈길을 끌며, 로또·담배 소매점과 콜라보 가능한 샵인샵 브랜드 입니다.',
            img: [
                {id: 1,  src: "./img/portfolio/mammothtaco/taco_1.jpg", category: "맘모스타코"},
                {id: 2,  src: "./img/portfolio/mammothtaco/taco_2.jpg", category: "맘모스타코"},
                {id: 3,  src: "./img/portfolio/mammothtaco/taco_3.jpg", category: "맘모스타코"},
                {id: 4,  src: "./img/portfolio/mammothtaco/taco_4.jpg", category: "맘모스타코"},
                {id: 5,  src: "./img/portfolio/mammothtaco/taco_5.jpg", category: "맘모스타코"},
                {id: 6,  src: "./img/portfolio/mammothtaco/taco_6.jpg", category: "맘모스타코"},
                {id: 7,  src: "./img/portfolio/mammothtaco/taco_7.jpg", category: "맘모스타코"},
                {id: 8,  src: "./img/portfolio/mammothtaco/taco_8.jpg", category: "맘모스타코"},
                {id: 9,  src: "./img/portfolio/mammothtaco/taco_9.jpg", category: "맘모스타코"},
                {id: 10,  src: "./img/portfolio/mammothtaco/taco_10.jpg", category: "맘모스타코"},
                {id: 11,  src: "./img/portfolio/mammothtaco/taco_11.jpg", category: "맘모스타코"},
                {id: 12,  src: "./img/portfolio/mammothtaco/taco_12.jpg", category: "맘모스타코"},
                {id: 13,  src: "./img/portfolio/mammothtaco/taco_13.jpg", category: "맘모스타코"},
            ]
        },
    ]
}

function Portfolio() {

    const location = useLocation();
    const { target } = location.state || {};

    const [tab, setTab] = useState('까몬');
    const [isSliderOpen, setSliderOpen] = useState(false);
    const [entryIndex, setEntryIndex] = useState(0);
    const [allImgs, setAllImgs] = useState([])

    const isMobile = useIsMobile(breakpoint.medium.replace('px', ''));

    // useEffect(() => {
    //     console.log(isMobile)
    // }, [isMobile])

    useEffect(() => { // main 페이지에서 슬라이드로 들어올 때
        if (target) setTab(target)
    }, [target])

    useEffect(() => { // 슬라이드 열려있을 땐 스크롤 고정
        if (isSliderOpen) {
            document.body.style.overflow = 'hidden'; // 스크롤 잠금
            document.querySelector('.root-block').style.overflow = 'hidden'
          } else {
            document.body.style.overflow = 'auto'; // 스크롤 해제
            document.querySelector('.root-block').style.overflow = 'auto'
          }
    }, [isSliderOpen])
    
    useEffect(() => {

        let allImages = [];
    
        // 이미지 한 곳에 다 담기
        portfolioData.contents.forEach((content) => {
            content.img.forEach(i => {
                allImages.push(i);
            });
        });
    
        setAllImgs(allImages)
    }, []);


  return (
    <RootBlock className='root-block'>
        {isSliderOpen && <FullScreenSlider
            data={portfolioData.contents.find(data => data.name == tab).img}
            onClose={() => setSliderOpen(false)}
            firstIndex={entryIndex}
        />}

        {/* <PreloadedImages className='preloading-zone'>
            {portfolioData.contents.map((content) => 
                content.img.map((i, index) => (
                    <img key={index} src={i.src} alt={`preload-${index}`} style={{ display: 'none' }} />
                ))
            )}
        </PreloadedImages> */}

        <Header isVisible={true}/>

        <BasicContainer>

            <PortfolioHead>
                <PortfolioTitle>인테리어 {isMobile ? <br /> : ''}포트폴리오</PortfolioTitle>
                <PortfolioSubtitle>프랜차이즈 인큐베이팅 전문 기업인 {isMobile ? <br /> : ''}리브랜더와 함께 나만의 브랜드를 완성해보세요</PortfolioSubtitle>
            </PortfolioHead>

                <ScrollableDiv>
                    <SimpleBar forceVisible='x' style={{ maxHeight: 1000 }}>
                        <TabMenu>
                        {portfolioData.contents.map((data, idx) => {
                                return (
                                    <TabMenuItem key={'tab'+idx}
                                        onClick={() => setTab(data.name)}
                                        style={{color: data.name == tab ? '#000' : '#B5B5B5'}}
                                    >
                                        {data.name}
                                    </TabMenuItem>
                                )
                        })}
                        </TabMenu>
                    </SimpleBar>
                </ScrollableDiv>

            <Description>
                <DescriptionTitle>
                    {portfolioData.contents.find(data => data.name == tab).title}
                </DescriptionTitle>
                <DescriptionSubtitle>
                    {portfolioData.contents.find(data => data.name == tab).subTitle}
                </DescriptionSubtitle>
            </Description>
        </BasicContainer>

        <Gallary>
                    {/* {
            portfolioData.contents.find(data => data.name == tab).img.map((i, idx) => {
                return <GallaryImg
                            src={i.src}
                            key={'gallary-img'+idx}
                            alt='gallary-img'
                            onClick={() => {
                                setSliderOpen(true); setEntryIndex(idx);
                            }}
                        />
            })
        } */}
        {allImgs.map((img, idx) => {
            if (img.category == tab) {
                return (<GallaryImg
                    src={img.src}
                    key={'gallary-img'+idx}
                    alt='gallary-img'
                    onClick={() => {
                        setSliderOpen(true); setEntryIndex(img.id -1);
                    }}
                />)
            }
        })}
        </Gallary>

        <Footer />

    </RootBlock>
  )
}

export default Portfolio