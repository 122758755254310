import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UrlRefresh from './util/hooks';
import Main from './pages/Main';
import ServiceIntro from './pages/ServiceIntro';
import CompanyIntro from './pages/CompanyIntro';
import Contact from './pages/Contact';
import Portfolio from './pages/Portfolio';

const AppRoutes = () => {

    // https://velog.io/@102wy/safari-에서-뒤로가기시-alert-confirm-이-뜨지않는-현상
    useEffect(() => {
        const handlePopState = () => {
            window.location.reload();
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    return (
        <Router>
            {/* {metatag} */}
            <UrlRefresh />
            <Routes>
                <Route path={"/"} element={<Main/>} />
                <Route path={"/service"} element={<ServiceIntro/>} />
                <Route path={"/company"} element={<CompanyIntro/>} />
                <Route path={"/contact"} element={<Contact/>} />
                <Route path={"/portfolio"} element={<Portfolio/>} />
            </Routes>
        </Router>
    );
};

export default AppRoutes;



