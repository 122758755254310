import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { CounselBtn, ScrollDownBox } from './Main';
import useMobile from '../util/useMobile';

const MainBg = styled.div`
    background: ${(props)=> `url(${props.$bgUrl}) no-repeat center`};;
    background-size: cover;
    width: 100%;
    height: 100svh;
    position: relative;
`

const MainSectionTxtWrap = styled.div`
    position: absolute;
    top: 49%;
    right: 22.5%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width:1024px) {
        padding: 0px 20px;
        top: 45.5%;
        left: 0;
        right: 0;
    }
`

const MainSectionTxt = styled.div`
    font-size: 1.125rem;
    font-weight: 400;
    &.port {
        font-size: 3.75rem;
        line-height: 1;
        font-weight: 700;
        @media (max-width:1024px) {
            font-size: 2.25rem;
        }
    }
    @media (max-width:1024px) {
        font-size: 0.875rem;
        span {
            display: block;
        }
    }
`

const MainContainer = styled.div`
    padding: 0px 40px;
    @media (max-width:1024px) {
        padding: 0px;
    }
`

const MainWrap = styled.div`
    max-width: 1460px;
    margin: 0 auto;
`

const MainTxtWrap = styled.div`
    padding: 200px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    @media (max-width:1024px) {
        padding: 200px 20px 150px;
    }
`

const MainTxtTitle = styled.div`
    width: 783px;
    font-size: 4.375rem;
    font-weight: 700;
    line-height: 1.2;
    @media (max-width:1024px) {
        max-width: 320px;
        width: 100%;
        font-size: 2.25rem;
        span {
            display: block;
        }
    }
`

const MainTxtSubTitle = styled.div`
    font-size: 1.25rem;
    font-weight: 400;
    color: #727272;
    @media (max-width:1024px) {
        font-size: 0.875rem;
    }
`

const MainTxtSubTitleWrap = styled.div`
    margin-top: 50px;
`

const ServiceSection1Img = styled.div`
    position: relative;
    img {
        width: 100%;
        &.logo {
            max-width: 300px;
            width: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 5;
            @media (max-width:1024px) {
                max-width: 200px;
            }
        }
    }
`

const ServiceSection1TxtWrap = styled.div`
    margin-top: 60px; 
    display: flex;
    gap: 100px;
    @media (max-width:1024px) {
        padding: 0px 20px;
        flex-wrap: wrap;
        gap: 30px;
        margin-top: 30px;
    }
`

const ServiceSection1Title = styled.div`
    flex-basis: 50%;
    font-size: 3.125rem;
    font-weight: 700;
    span {
        color: #EA6D00;
    }
    @media (max-width:1024px) {
        font-size: 1.5rem;
        flex-basis: 100%;
    }
`

const ServiceSection1SubWrap = styled.div`
    flex-basis: 50%;
    @media (max-width:1024px) {
        flex-basis: 100%;
    }
`

const ServiceSection1SubTitle = styled.div`
    font-size: 1.125rem;
    font-weight: 400;
    @media (max-width:1024px) {
        font-size: 1rem;
    }
`

const ServiceSection1Btn = styled.div`
    margin-top: 50px;
    display: flex;
    gap: 20px;
    background-color: #F8F8F8;
    max-width: fit-content;
    width: 100%;
    align-items: center;
    padding: 11px 16px;
    border-radius: 8px;
    min-height: 52px;
    p {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1;
        @media (max-width:1024px) {
            font-size: 0.875rem;
        }
        &.summing {
            font-size: 1.125rem;
            font-weight: 700;
        }
    }
    @media (max-width:1024px) {
        max-width: none;
        flex-direction: column;
        align-items: flex-start;
        height: 90px;
        justify-content: center;
    }
`

const ServiceSection1Line = styled.div`
    width: 1px;
    height: 22px;
    background-color: #A2A2A2;
    @media (max-width:1024px) {
        display: none;
    }
`

const ServiceSection2 = styled.div`
    margin: 200px 0px 200px 0px;
    display: flex;
    align-items: center;
    gap: 100px;
    @media (max-width:1024px) {
        margin: 150px 0px 150px 0px;
        flex-wrap: wrap;
        gap: 30px;
    }
`

const ServiceSection2ImgWrap = styled.div`
    width: 100%;
    img {
        width: 100%;
    }
`

const ServiceSection2TxtWrap = styled.div`
    max-width: 630px;
    width: 100%;
    @media (max-width:1024px) {
        padding: 0px 20px;
        max-width: none;
    }
`

const ServiceSection2Title = styled.div`
    p {
        font-size: 3.125rem;
        font-weight: 700;
        @media (max-width:1024px) {
            font-size: 1.5rem;
        }
    }
    span {
        color: #EA6D00;
    }
`

const ServiceSection2SubTitle = styled.div`
    margin-top: 30px;
    span {
        /* display: block; */
        &.block {
            display: block;
        }
        @media (max-width:1024px) {
            &.block {
                display: inline;
            }
            display: block;
        }
    }
    p {
        font-size: 1.125rem;
        font-weight: 400;
        @media (max-width:1024px) {
            font-size: 1rem;
        }
    }
`

const ServiceSection2Btn = styled(ServiceSection1Btn)`
    max-width: fit-content;
    margin-top: 100px;
    @media (max-width:1024px) {
        max-width: none;
        margin-top: 50px;
    }
`

const ServiceSection3 = styled(ServiceSection2)`
    margin: 0px 0px 200px 0px;
    @media (max-width:1024px) {
        flex-direction: column-reverse;
        margin: 0px 0px 200px 0px;
        align-items: flex-start;
    }
`

const ServiceSection3SubWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width:1024px) {
        align-items: flex-start;
    }
`

const ServiceSection3TxtWrap = styled(ServiceSection2TxtWrap)`
    text-align: right;
    @media (max-width:1024px) {
        text-align: left;
    }
`

const ServiceSection3Btn = styled(ServiceSection1Btn)`
    max-width: fit-content;
    @media (max-width:1024px) {
        max-width: none;
    }
`

const ServiceWrap = styled.div`
    max-width: 1840px;
    margin: 0 auto;
    padding: 200px 0px 300px;
    border-top: 1px solid #727272;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (max-width:1024px) {
        padding: 200px 0px 228px;
        margin: 0px 20px;
    }
`

const ServiceTitle = styled.div`
    font-size: 3.125rem;
    font-weight: 700;
    @media (max-width:1024px) {
        font-size: 1.875rem;
        text-align: center;
        span {
            display: block;
        }
    }
`

const ServiceBoxWrap = styled.div`
    display: flex;
    gap: 40px;
    margin: 100px 0px 70px;
    width: 100%;
    @media (max-width:1024px) {
        flex-wrap: wrap;
        margin: 50px 0px 0px;
        gap: 20px;
    }
`

const ServiceBox = styled.div`
    background-color: #F8F8F8;
    padding: 50px;
    flex-basis: 50%;
    @media (max-width:1024px) {
        padding: 20px 16px;
        width: 100%;
        flex-basis: 100%;
    }
`

const ServiceBoxSmallTitle = styled.div`
    font-size: 1.125rem;
    line-height: 1;
    letter-spacing: -0.18px;
    @media (max-width:1024px) {
        font-size: 0.75rem;
        letter-spacing: -0.12px;
    }
`

const ServiceBoxTitleWrap = styled.div`
    display: flex;
    margin-top: 20px;
    gap: 140px;
    @media (max-width:1365px) {
        gap: 70px;
    }
    @media (max-width:1024px) {
        flex-wrap: wrap;
        margin-top: 12px;
        gap: 20px;
    }
`

const ServiceBoxTitle = styled.div`
    font-size: 2.5rem;
    font-weight: 700;
    min-width: 174px;
    &.custom {
        min-width: 208px;
    }
    line-height: 1;
    @media (max-width:1024px) {
        font-size: 1.5rem;
        min-width: none;
        width: 100%;
    }
`

const ServiceBoxDesc = styled.div`
    font-size: 1.125rem;
    font-weight: 400;
    span {
        display: block;
        @media (max-width:1623px) {
            display: inline;
        }
    }
    p {
        &.bold{
            color: #EA6D00;
            font-weight: 700;
            display: block;
        }
    }
    @media (max-width:1024px) {
        font-size: 1rem;
    }
`

function ServiceIntro() {

    const navigate = useNavigate();
    const isMobile = useMobile();

    return (
        <>
            <Header isVisible={true} />
            <MainBg $bgUrl={isMobile ? './../img/service_main_mobile.png' : './../img/service_main.png'}>
                <MainSectionTxtWrap>
                    <MainSectionTxt className='port'>서비스 소개</MainSectionTxt>
                    <MainSectionTxt>가맹본부에 꼭 맞는 <span>서비스를 찾아드립니다</span></MainSectionTxt>
                </MainSectionTxtWrap>
                <ScrollDownBox>
                    <img src={isMobile ? './../img/scroll_down_mobile.png' : './../img/scroll_down.png'} alt='scroll_down' />
                </ScrollDownBox>
            </MainBg>
            <MainContainer>
                <MainWrap>
                    <MainTxtWrap>
                        <MainTxtTitle>리:브랜더의 <span>서비스에 대해서</span> 알려드릴게요</MainTxtTitle>
                        <MainTxtSubTitleWrap>
                            <MainTxtSubTitle>프랜차이즈 인큐베이팅 전문 기업인</MainTxtSubTitle>
                            <MainTxtSubTitle>리브랜더와 함께 나만의 브랜드를 완성해보세요</MainTxtSubTitle>
                        </MainTxtSubTitleWrap>
                    </MainTxtWrap>
                    <ServiceSection1Img>
                        <img src={isMobile ? './img/service_section1_mobile.png' : './img/service_section1.png'} />
                        <img src='./img/logo_white.png' className='logo' />
                    </ServiceSection1Img>
                    <ServiceSection1TxtWrap>
                        <ServiceSection1Title>
                            <p>가맹사업 시작을 알리는</p>
                            <p>기분좋은 출발</p>
                            <span>정보공개서 등록</span>
                        </ServiceSection1Title>
                        <ServiceSection1SubWrap>
                            <ServiceSection1SubTitle>
                                <p>가맹사업의 시작은 정보공개서 등록입니다.</p>
                                <p>이 과정에서 브랜드의 성장전략을 수립합니다.</p>
                                <p>초기의 결정은 추후 가맹사업이 활성화 됐을 경우</p>
                                <p>수익구조에 큰 영향을 미치기 때문에 신중해야 합니다.</p>
                                <p>리브랜더가 고민을 같이 해결해드립니다.</p>
                            </ServiceSection1SubTitle>
                            <ServiceSection1Btn>
                                <p className='summing'>summing-up</p>
                                <ServiceSection1Line />
                                <p>정보공개서 등록</p>
                            </ServiceSection1Btn>
                        </ServiceSection1SubWrap>
                    </ServiceSection1TxtWrap>
                    <ServiceSection2>
                        <ServiceSection2ImgWrap>
                            <img src={isMobile ? './img/service_section2_mobile.png' : './img/service_section2.png'} />
                        </ServiceSection2ImgWrap>
                        <ServiceSection2TxtWrap>
                            <ServiceSection2Title>
                                <p>모두에게 ‘잘 팔리는’</p>
                                <p><span>디자인</span>을 기획합니다</p>
                            </ServiceSection2Title>
                            <ServiceSection1SubWrap>
                                <ServiceSection2SubTitle>
                                    <p>프랜차이즈 사업의 중요한 한 축은</p>
                                    <p>바로 디자인입니다.</p>
                                    <p>리브랜더는 트렌디하고 잘 팔리는 디자인을 추구합니다.</p>
                                    <p>홈페이지, 인테리어, 인쇄물 등 브랜딩을 위해</p>
                                    <p>최적의 솔루션을 제공합니다.</p>
                                </ServiceSection2SubTitle>
                                <ServiceSection2Btn>
                                    <p className='summing'>summing-up</p>
                                    <ServiceSection1Line />
                                    <p>디자인 : 인테리어, 홈페이지, 인쇄물</p>
                                </ServiceSection2Btn>
                            </ServiceSection1SubWrap>
                        </ServiceSection2TxtWrap>
                    </ServiceSection2>
                    <ServiceSection3>
                        <ServiceSection3TxtWrap>
                            <ServiceSection2Title>
                                <p>어렵기만 한 <span>마케팅</span></p>
                                <p>한 번에 해결</p>
                            </ServiceSection2Title>
                            <ServiceSection3SubWrap>
                                <ServiceSection2SubTitle>
                                    <p>가맹점 모집 광고</p>
                                    <p>가맹희망자 상담</p>
                                    <p>가맹점 홍보 등</p>
                                    <p>체계적 시스템에 의한<span className='block'> 순도 높은 마케팅 <span>저희가 대신합니다.</span></span></p>
                                </ServiceSection2SubTitle>
                                <ServiceSection3Btn>
                                    <p className='summing'>summing-up</p>
                                    <ServiceSection1Line />
                                    <p>마케팅 : 가맹점 모집, 가맹점 홍보</p>
                                </ServiceSection3Btn>
                            </ServiceSection3SubWrap>
                        </ServiceSection3TxtWrap>
                        <ServiceSection2ImgWrap>
                            <img src={isMobile ? './img/service_section3_mobile.png' : './img/service_section3.png'} />
                        </ServiceSection2ImgWrap>
                    </ServiceSection3>
                </MainWrap>
                <ServiceWrap>
                    <ServiceTitle>어떤 리:브랜더 서비스가 <span> 필요하신가요?</span></ServiceTitle>
                    <ServiceBoxWrap>
                        <ServiceBox>
                            <ServiceBoxSmallTitle className='raleway'>Incubating</ServiceBoxSmallTitle>
                            <ServiceBoxTitleWrap>
                                <ServiceBoxTitle>인큐베이팅</ServiceBoxTitle>
                                <ServiceBoxDesc>
                                    <p>정보공개서 등록부터 디자인 그리고 마케팅까지<span> 모두 이용하는 종합 서비스</span></p>
                                    <p className='bold'>본 서비스는 보증금(deposit) 계약입니다.</p>
                                    <p className='bold'>계약 종료시 반환드립니다.</p>
                                    <p className='bold'>상담을 통해 확인해보세요.</p>
                                </ServiceBoxDesc>

                            </ServiceBoxTitleWrap>
                        </ServiceBox>
                        <ServiceBox>
                            <ServiceBoxSmallTitle className='raleway'>Customizing</ServiceBoxSmallTitle>
                            <ServiceBoxTitleWrap>
                                <ServiceBoxTitle className='custom'>커스터마이징</ServiceBoxTitle>
                                <ServiceBoxDesc>
                                    <p>정보공개서 등록</p>
                                    <p>디자인</p>
                                    <p>마케팅</p>
                                    <p>필요한 서비스만 이용하는 서비스</p>
                                </ServiceBoxDesc>
                            </ServiceBoxTitleWrap>
                        </ServiceBox>
                    </ServiceBoxWrap>
                    <CounselBtn style={{marginTop:'50px'}} onClick={()=>{navigate("/contact")}}>
                        <p>상담 신청하기</p>
                    </CounselBtn>
                </ServiceWrap>
            </MainContainer>
            <Footer />
        </>
    )
}

export default ServiceIntro