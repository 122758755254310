import React, { useEffect } from "react";
import AppRoutes from "./routes";

function App() {

  // const setScreenHeight = () => {
  //   const vh = window.innerHeight * 0.01;
  //   document.documentElement.style.setProperty('--vh', `${vh}px`);
  // };

  // useEffect(() => {
  //   setScreenHeight();
  //   window.addEventListener('resize', setScreenHeight);

  //   return () => window.removeEventListener('resize', setScreenHeight);
  // }, []);

  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
