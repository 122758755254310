import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { breakpoint, lg, md, sm, xs } from '../styled';
import { isRequired, nameCheck, telCheck, profitCheck } from '../util/validators';
import { useIsMobile } from '../util/hooks';
import { DatePicker } from "antd";
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { dateToString } from '../util/helpers';

import locale from "antd/lib/locale/ko_KR";
import { ConfigProvider } from "antd";

import "dayjs/locale/ko"
import dayjs from "dayjs"
import { ScrollDownBox } from './Main';

const RootBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background-color: #fff;
`;

const BasicContainer = styled.div`
    width: calc(100% - 80px);
    margin: 0 auto;

    ${lg`
        width: calc(100% - 120px);
    `}

    ${md`
        width: calc(100% - 120px);
    `}

    ${sm`
        width: calc(100% - 80px);
    `}

    ${xs`
        width: calc(100% - 40px);
    `}
`;

const BannerContainer = styled.div`
/*     
    width: 100%;

    img{
        width: 100%;
        height: 100svh;
        object-fit: cover;
    } */
    position: relative;
    width: 100%;
    height: 100svh;
    background: ${(props)=> `url(${props.$bgUrl}) no-repeat center`};
    background-size: cover;

`;

const MainSectionTxtWrap = styled.div`
    position: absolute;
    top: 49%;
    right: 26.5%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width:1024px) {
        padding: 0px 20px;
        top: 45.5%;
        left: 0;
        right: 0;
    }
`

const MainSectionTxt = styled.div`
    font-size: 1.125rem;
    font-weight: 400;
    span {
            display: block;
        }
    &.port {
        font-size: 3.75rem;
        line-height: 1;
        font-weight: 700;
        @media (max-width:1024px) {
            font-size: 2.25rem;
        }
    }
    @media (max-width:1024px) {
        font-size: 0.875rem;
    }
`

const StyledContact = styled.div`
    display: grid; grid-template-columns: 1fr 1fr;
    margin-top: 100px;

    ${md`
        display: flex; flex-direction: column;
    `}
`;

const ContactPageTitle = styled.div` /* 타이틀 (Contact.) */
    font-family: Raleway;
    font-size: 125px;
    font-weight: 600;
    letter-spacing: -0.01em;
    line-height: 1;

    p{
        margin-right: 100px;
        text-wrap: nowrap;
        position: sticky;
        top: 108px;
    }

    img{ /* 점 처럼 생긴 사각형 */
        width: 14px;
        height: 14px;
        margin-left: 9px;
        transform: translateY(-1px);
    }

    ${md`
        font-size: 60px;
        margin-bottom: 50px;

        img {
            width: 6px; height: 6px;
            margin-left: 11px;
        }

        p{
            position: static;
        }
    `}
`;

const ContactPageForm = styled.div`
    display: grid; grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-bottom: 300px;

    ${lg`
        display: flex; flex-direction: column;
    `}
`;

const InputBox = styled.div`
    position: relative;
    ${p => p.$isFullWidth ? 'grid-column: span 2' : ''}
`;

const InputTitle = styled.div`
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: left;
    color: #000;
    padding-bottom: 10px;
`;

const DatePickerWrapper = styled.div`
    width: 100%;
    height: 60px;
    ${md`height: 54px;`}
    position: absolute; bottom: 0;
    z-index: -999;

`;

const CustomInput = styled.input`
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 17px 16px 15px 16px;
    border: 1px solid #B5B5B5; 
    border-radius: 0;

    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    text-align: left;

    &::placeholder{
        color: #B5B5B5;
        opacity: ${p => p.$isFocus ? 0 : 1};

    }

    &:focus{
        border: 1px solid #000000;
        color: #000000;
        outline: none;
        box-shadow: none;

        &::placeholder {
            opacity: 0; /* focus 시 placeholder의 opacity를 0으로 설정 */
        }
    }

    ${md`
        height: 54px;
    `}
`;

const ScrollBox = styled.div`
    height: 260px;
    overflow-y: auto;
    padding: 16px;

    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    border: 1px solid #B5B5B5;
    transition: 0.4s all;

    a{
        text-decoration: underline;
        cursor: pointer;
        color: #000000;
    }

    ul{
        margin-left: 16px;
        list-style: circle;
    }

    &::-webkit-scrollbar {
        width: 36px;
        
    }
    &::-webkit-scrollbar-thumb {
        background: black;
        border-radius: 18px;
        border: 16px solid transparent;
        background-clip: padding-box;
        min-height: 80px;
    }
`;

const CheckboxWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 30px;
    margin: 20px 0;

    ${p => p.$isFullWidth ? 'grid-column: span 2' : ''}

    ${lg`
        display: flex; flex-direction: column;
        margin: 20px 0 20px 0;

        div:first-child{
            margin-bottom: 30px;
        }
    `}
`;

const CustomCheckbox = styled.div` /* 서비스 의뢰 타입, 약관 */
    display: flex; align-items: center;

    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    cursor: pointer;
    /* 특정 박스는 grid 전부 차지하게 */
    grid-column: ${p => p.$isFullWidth ? ': span 2' : 'inherit'};

    img{ /* 체크박스 이미지 간격 */
        width: 20px; height: 20px;
        padding-right: 16px; box-sizing: content-box;
    }

    p {
        display: flex; align-items: center;
        height: 20px;
    }

    span{ /* bold체 */
        font-weight: 700;
    }

    /* 약관 동의 margin */
    ${p => p.$isTerms && sm`
        margin: 20px 0 0 0;
       
    `}
`;

const CustomButton = styled.button`

    width: 100%; height: 76px;
    
    border-radius: 10px;
    background-color: #000;
    border: none;
    cursor: pointer;

    color: #FFF;
    text-align: center;
    font-weight: 700;
    font-size: 22px;

    margin-top: 50px;

    p{
        position: relative; top: 1px;
    }

    ${md`
        margin-top: 50px;
        height: 48px;
        font-size: 16px;
    `}

    &:hover{
        background-color: #404040;
    }
`;

function Contact() {

    const [isOpenTerms, setIsOpenTerms] = useState(false);
    const [dateFocusState, setDateFocusState] = useState(false);

    // 브라우저 모바일 판독기
    const isMobile = useIsMobile(
        breakpoint.medium.replace('px', '') // 브레이크 포인트 'px'까지 들어있는 문자열이라 ㅎ;;
    );

    const [userData, setUserData] = useState({
        name: "",
        tel: "",
        address: "",
        serviceType: "",
        startDate: dateToString(new Date()),
        avgProfit: "0",
        termsAgree: false
    });

    useEffect(() => { /* 데이트 피커 한글 패치 */
        dayjs.locale("ko");
    }, []);

    const inputRefs = useRef([]); // input 태그들 focus() 하려고
    const datePickerRef = useRef(null);

    const setRef = (index) => (element) => { // input ref들 모아놓기
        inputRefs.current[index] = element;
    };

    const handleSend = async (userData) => { 
        const response = await fetch('https://test.rebrander.kr/api/email', {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(userData),
        });
        const jsonData = await response.json();

        return jsonData
    };

    const handleSubmit = async () => {

        // console.log(inputRefs)

        if (!isRequired(userData.name)) { // 이름 빈 칸
            inputRefs.current[0].focus();
        } else if (!isRequired(userData.tel)) { // 전화번호 빈 칸
            inputRefs.current[1].focus()
        } else if (!telCheck(userData.tel)) { // 전화번호 검사
            inputRefs.current[1].focus()
        } else if (!isRequired(userData.address)) { // 주소 빈 칸
            inputRefs.current[2].focus()
        } else if (userData.serviceType == "") { // 서비스 타입 미선택
            alert("원하시는 서비스를 선택해 주세요.")
            inputRefs.current[3].focus();
        } else if (!isRequired(userData.startDate)) { // 영업 시작일 빈 칸
            // inputRefs.current[5].focus()
            datePickerRef.current.nativeElement.click()
        } else if (!isRequired(userData.avgProfit)) { // 매출액 빈칸
            inputRefs.current[6].focus()
        } else if (!profitCheck(userData.avgProfit)) { // 매출액 검사
            inputRefs.current[6].focus()
        } else if (!userData.termsAgree) { // 약관 미동의
            inputRefs.current[7].focus()
            alert('개인정보처리방침에 동의해 주세요.')
        } else {

            const userDataCopy = { ...userData }

            setUserData({ // 초기화
                name: "",
                tel: "",
                address: "",
                serviceType: "",
                startDate: dateToString(new Date()),
                avgProfit: 0,
                termsAgree: false
            });
            
            handleSend(userDataCopy);

            alert('상담 신청이 완료되었습니다.')

            window.location.reload();

        }
    };

  return (
    <RootBlock>
        <Header isVisible={true}/>
        <BannerContainer $bgUrl={isMobile ? './../img/contact_mobile.png' : './../img/contact.png'}>
            <MainSectionTxtWrap>
                <MainSectionTxt className='port'>상담 신청</MainSectionTxt>
                <MainSectionTxt>투명한 업무처리, 편리한 서비스<span> 리브랜더를 통해 경험해보세요</span></MainSectionTxt>
            </MainSectionTxtWrap>
            <ScrollDownBox>
                <img src={isMobile ? './../img/scroll_down_mobile.png' : './../img/scroll_down.png'} alt='scroll_down' />
            </ScrollDownBox>
        </BannerContainer>

        <BasicContainer>
            <StyledContact className='contact-container'>

                <ContactPageTitle className='contact-title'>
                    <p>Contact<img src='./img/rectangle_black.png' /></p>
                </ContactPageTitle>

                <ContactPageForm className='contact-form'>
                    
                    <InputBox>
                        <InputTitle>이름</InputTitle>
                        <CustomInput
                            placeholder='이름을 입력해주세요'
                            ref={setRef(0)}
                            value={userData.name}
                            maxLength={50}
                            onChange={(e) => {
                                setUserData({
                                    ...userData, name: e.target.value
                                })
                            }}
                        />
                    </InputBox>
                    
                    <InputBox>
                        <InputTitle>전화번호</InputTitle>
                        <CustomInput
                            placeholder='상담이 가능한 연락처를 입력해주세요'
                            ref={setRef(1)}
                            value={userData.tel}
                            maxLength={11}
                            onChange={(e) => {
                                setUserData({
                                    ...userData, tel: e.target.value
                                })
                            }}
                        />
                    </InputBox>
                    
                    <InputBox style={{marginBottom: '20px'}} $isFullWidth={true}> {/* 여기만 margin 좀 더 큼 */}
                        <InputTitle>주소</InputTitle>
                        <CustomInput
                            placeholder='운영하고 있는 매장 주소를 알려주세요'
                            ref={setRef(2)}
                            value={userData.address}
                            maxLength={100}
                            onChange={(e) => {
                                setUserData({
                                    ...userData, address: e.target.value
                                })
                            }}
                        />
                    </InputBox>
                    
                    <InputBox $isFullWidth={true}>
                        <InputTitle>리브랜더 서비스 선택</InputTitle>
                        <CheckboxWrapper className='checkbox-wrapper'>
                        <CustomCheckbox
                                className='checkbox'
                                tabIndex={0}
                                ref={setRef(4)}
                                onClick={() => {
                                    setUserData({
                                        ...userData, serviceType: "인큐베이팅"
                                    })
                                }}
                            >
                                {
                                userData.serviceType === "인큐베이팅" ?
                                <img src='./img/checkbox_on_4x.png' alt='select-service-type' />
                                :
                                <img src='./img/checkbox_off_4x.png' alt='select-service-type' />
                                }

                                <p>편리하게 한번에 해결하는&nbsp;<span>인큐베이팅</span></p>
                            </CustomCheckbox>

                            <CustomCheckbox
                                className='checkbox'
                                tabIndex={0}
                                ref={setRef(3)}
                                onClick={() => {
                                    setUserData({
                                        ...userData, serviceType: "커스터마이징"
                                    })
                                }}
                            >
                                {
                                userData.serviceType === "커스터마이징" ?
                                <img src='./img/checkbox_on_4x.png' alt='select-service-type'/>
                                :
                                <img src='./img/checkbox_off_4x.png' alt='select-service-type' />
                                }

                                <p>필요한 서비스만 이용하는&nbsp;<span>커스터마이징</span></p>


                            </CustomCheckbox>


                        </CheckboxWrapper>
                    </InputBox>

                    <InputBox>
                        <InputTitle>영업 시작일</InputTitle>
                        <CustomInput
                            placeholder={dateFocusState ? '' : '영업 시작일을 입력해주세요'}
                            ref={setRef(5)}
                            value={userData.startDate}
                            onClick={() => datePickerRef.current.nativeElement.click()} // 데이트 피커 클릭
                            onChange={() => {}}
                        />

                        <DatePickerWrapper> {/* 실제 데이터 피커 input창은 zIndex 하위로 내리기 */}
                            <ConfigProvider locale={locale}> {/* 데이트 피커 한글화 세팅 */}
                                <DatePicker
                                        className='date-picker'
                                        ref={datePickerRef}
                                        style={{width: '100%', height: '100%'}}
                                        onFocus={(e) => e.target.blur()} // 모바일에서 키보드 바로 내려버리기
                                        onChange={(value) => {
                                            setUserData({
                                                ...userData, startDate: dateToString(value.$d)
                                            })
                                        }}
                                        onOpenChange={(bool) => setDateFocusState(bool)} // datepicker open 상태 추적

                               />
                            </ConfigProvider>
                         </DatePickerWrapper>

                        
                    </InputBox>

                    <InputBox>
                        <InputTitle>월 평균 매출액 (만원)</InputTitle>
                        <CustomInput
                            ref={setRef(6)}
                            value={userData.avgProfit}
                            onChange={(e) => {
                                if (!isNaN(e.target.value)) {
                                    setUserData({
                                        ...userData, avgProfit: e.target.value
                                    })
                                }
                            }}
                        />
                    </InputBox>

                    <div className='divider'
                        style={{gridColumn: 'span 2', borderBottom: '1px solid #727272', marginTop: '20px'}}/>

                   <CustomCheckbox
                        className='custom-checkbox'
                        tabIndex={0}
                        ref={setRef(7)}
                        style={{fontWeight: '700', gridColumn: 'span 2'}} $isTerms={true} $isFullWidth={true}
                        onClick={() => {
                            setUserData({
                                ...userData, termsAgree: !userData.termsAgree
                            })
                        }}
                   >
                        {userData.termsAgree ?
                            <img src='./img/checkbox_on_4x.png' alt='select-service-type' />
                            :
                            <img src='./img/checkbox_off_4x.png' alt='select-service-type' />
                        }
                        <p><span style={{textDecoration: 'underline'}}>개인정보처리방침</span>에 동의합니다.</p>

                        <div 
                            className='terms-open-btn'
                            onClick={(e) => {
                                e.stopPropagation()
                                setIsOpenTerms(!isOpenTerms)
                            }}
                            style={{marginLeft: 'auto'}}
                        >
                        {
                            isOpenTerms ?
                            <img src='./img/arrow_up_4x.png' alt='arrow_up' style={{paddingRight: 0}} />
                            :
                            <img src='./img/arrow_down_4x.png' alt='arrow_down' style={{paddingRight: 0}} />
                        }
                        </div>
                    </CustomCheckbox>


                    {<ScrollBox
                        style={{
                            gridColumn: 'span 2',
                            height: isOpenTerms ? '260px' : 0,
                            padding: isOpenTerms ? '16px' : '0 16px',
                            border: isOpenTerms ? '1px solid #B5B5B5' : '1px solid transparent'
                        }}>



    <span style={{fontWeight: 'bold'}}>개인정보 처리방침</span>
    <p>‘주식회사 리브랜더‘는 이용자의 개인정보를 중시하며, “정보통신망 이용촉진 및 정보보호 등에 관한 법률“, “개인정보보호법“, 통신비밀보호법 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익보호에 최선을 다하고 있습니다.</p>
    <p>‘주식회사 리브랜더’는 이용자의 개인정보를 수집, 이용, 제공하는 경우 반드시 사전에 이용자에게 해당 내용을 알리고 동의절차를 거치며, 이용자가 동의하지 아니할 경우에는 이용자의 개인정보를 수집, 이용, 제공하지 않습니다. 단, 동의를 거부하는 경우 서비스의 전부 또는 일부 이용이 제한될 수 있습니다.</p>
    <br />
    <p>1. 개인정보의 수집 목적</p>
    <p>주식회사 리브랜더 (‘<a href="http://rebrander.kr">http://rebrander.kr</a>’ 이하 ‘리브랜더’)은(는) 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다.</p>
    <ul>
        <li>서비스 제공에 관한 계약이행 및 대금결제</li>
        <ul>
            <li>리브랜더에서 구매한 제품 또는 서비스에 대한 주문 접수 및 대금결제</li>
            <li>리브랜더에서 구매한 제품에 대한 배송 및 서비스 이행</li>
            <li>고객에 대한 A/S 실시</li>
        </ul>
        <li>회원관리</li>
        <ul>
            <li>회원제 서비스 이용 및 본인확인, 개인식별, 불량회원의 부정 이용 방지와 비인가 사용방지, 가입의사 확인, 연령확인, 불만처리 등 민원처리, 고지사항 전달</li>
        </ul>
        <li>신규서비스 개발 및 마케팅, 광고에의 활용</li>
        <ul>
            <li>신규 서비스 개발과 이벤트 행사에 따른 정보전달 및 맞춤서비스 제공</li>
            <li>인구통계학적 특성에 따른 서비스 제공 및 광고게재, 접속빈도파악, 회원의 서비스 이용에 대한 통계, 이벤트 등 광고성 정보 전달</li>
        </ul>
    </ul>
    <br />
    <p>2. 개인정보의 수집 항목</p>
    <ul>
        <li>이용자 식별정보: 아이디, 비밀번호, 휴대폰번호, 성명, 생년월일, 성별, 이메일</li>
        <li>결제 정보: 신용카드 결제정보, 은행계좌 정보, 휴대전화 결제 정보, 상품권 정보, 결제기록</li>
        <li>제품배송 및 서비스 이행: 배송지주소, 휴대폰번호, 유선전화번호</li>
        <li>제품 문의 및 이벤트 등 안내: 이메일수신여부, DM수신여부, SMS수신여부, TM수신여부</li>
        <li>서비스 이용 및 부정 이용방지: 서비스이용기록, 접속로그, 접속IP정보</li>
    </ul>
    <br />
    <p>3. 개인정보의 수집 방법</p>
    <p>주식회사 리브랜더 ("<a href="http://rebrander.kr">http://rebrander.kr</a>")는 다음의 방법으로 개인정보를 수집합니다.</p>
    <ul>
        <li>rebrander.com 웹플랫폼</li>
        <li>신청/상담 서면 양식</li>
        <li>전화</li>
    </ul>
    <br />
    <p>4. 개인정보의 처리 및 보유 기간</p>
    <p>‘리브랜더‘는 이용자의 개인정보를 이용자에게 서비스를 제공하는 기간 동안 보유하며, 서비스 제공을 위해서만 이용합니다. 아래와 같이 관계법령에 의하여 보존할 필요가 있는 경우에는 관계법령에 따라 일정한 기간 동안 개인정보를 보관합니다.</p>
    <ul>
        <li>서비스이용기록, 접속로그, 접속IP정보, 쿠키 : 3개월</li>
        <li>계약 또는 청약철회에 관한 기록 : 5년</li>
        <li>대금결제 및 재화 등의 공급에 관한 기록 : 5년</li>
        <li>소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</li>
    </ul>
    <br />
    <p>5. 개인정보의 파기</p>
    <p>‘리브랜더‘는 원칙적으로 이용자의 개인정보를 서비스와 무관한 제3자에게 제공하거나 공개하지 않으며, 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다.</p>
    <ul>
        <li>파기절차: 회사는 고객님께서 제공하신 모든 정보를 고객님께서 서비스를 제공받으시는 동안 보유하며 서비스 제공을 위해서만 이용하게 됩니다. 또한, 고객님께서 회원탈퇴를 요청한 경우 고객님의 개인정보는 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 일정 기간 저장된 후, 재생 불가능한 방법으로 저장장치에서 완전히 삭제되며, 어떠한 방법으로도 열람 또는 이용할 수 없도록 처리됩니다.</li>
        <li>파기방법: 종이에 출력된 정보는 파쇄하거나 소각을 통하여 파기하며, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</li>
    </ul>
    <br />
    <p>6. 이용자 및 법정대리인 권리와 그 행사방법</p>
    <p>이용자는 개인정보주체로서 다음과 같은 권리를 행사할 수 있습니다.</p>
    <ul>
        <li>이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다. 이용자의 개인정보 조회/수정을 위해서는 ‘개인정보변경‘(또는 ‘회원정보관리’ 등)을 통하여 가능하며, 가입해지(동의철회)를 위해서는 “회원탈퇴“를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 또한, 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.</li>
        <li>이용자가 개인정보의 오류에 대한 정정을 요청한 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.</li>
        <li>이용자 요청에 의해 해지 또는 삭제된 개인정보는 “3. 개인정보의 보유 및 이용기간“에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</li>
        <li>‘리브랜더’ 웹사이트는 14세 미만의 이용자의 개인 정보를 취급하지 않고 있습니다.</li>
    </ul>
    <br />
    <p>7. 개인정보 안전성 확보 조치</p>
    <p>‘리브랜더’는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.</p>
    <ul>
        <li>개인정보보호 내부 관리계획 수립 및 시행: 개인정보 보호책임자의 지정 등 개인정보 보호 조직의 구성 및 운영에 관한 사항 등을 포함하여 개인정보 내부관리계획을 수립하고, 매년 내부관리계획을 잘 시행하고 있는지를 점검하고 있습니다.</li>
        <li>개인정보에 대한 접근 통제 및 접근 권한 제한 조치: 개인정보에 대한 불법적인 접근을 차단하기 위해 개인정보 처리시스템에 대한 접근 권한의 부여 ㆍ변경 ㆍ말소 등에 관한 기준을 수립하여 시행하고 있으며, 침입차단시스템 및 침입탐지시스템을 설치ㆍ운영하고 있습니다. 또한 개인정보를 처리하는 직원을 최소한으로 관리하며, 개인정보처리시스템에서 개인정보의 다운로드가 가능한 직원들의 업무용 PC에 대해 외부 인터넷망과 내부망을 분리하여 개인정보유출 가능성을 줄이고 있습니다.</li>
        <li>개인정보 암호화 조치: 법령에서 암호화를 요구하고 있는 비밀번호, 고유식별정보, 계좌번호 및 카드번호 외에 이메일주소와 휴대전화번호 등을 추가로 암호화 하여 보관하고 있습니다. 또한 암호화 통신 등을 통하여 네트워크 상에서 개인정보를 안전하게 송수신하고 있습니다.</li>
        <li>개인정보 보관 및 위변조 방지 조치: 개인정보취급자가 개인정보처리시스템에 접속한 기록을 보관ㆍ관리하며, 개인정보의 오ㆍ남용, 분실ㆍ위조ㆍ변조 등을 방지하기 위하여 접속기록 등을 정기적으로 점검하며, 개인정보취급자의 접속기록이 위ㆍ변조 및 도난, 분실되지 않도록 해당 접속기록을 안전하게 보관하고 있습니다. 개인정보의 훼손에 대비하여 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 유출되거나 손상되지 않도록 방지하고 있습니다. 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 외부로부터 접근이 통제된 구역에 시스템을 설치하고 있으며, 출입통제 절차를 수립ㆍ운영하고 있습니다.</li>
        <li>개인정보보호기구 운영: 사내 개인정보보호기구 등을 통하여 개인정보처리방침의 이행사항 및 담당자의 준수 여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다.</li>
    </ul>
    <br />
    <p>8. 개인정보관리책임자 및 담당자</p>
    <p>고객 개인정보 관리책임자 및 담당자는 다음과 같으며 개인정보 관련 문의사항에 신속하고 성실하게 답변해 드리겠습니다.</p>
    <ul>
        <li>개인정보 관리 책임자</li>
        <ul>
            <li>성명: 현창희</li>
            <li>소속부서: 가맹사업전략부</li>
            <li>이메일 주소: <a href="mailto:rebrander@rebrander.kr">rebrander@rebrander.kr</a></li>
        </ul>
    </ul>
    <br />
    <p>9. 기타 개인정보침해에 대한 신고 또는 상담</p>
    <ul>
        <li>개인정보침해신고센터 전화 : (국번없이)118 URL : <a href="http://privacy.kisa.or.kr">http://privacy.kisa.or.kr</a></li>
        <li>정보보호마크인증위원회 전화 : 02-550-9531~2 URL : <a href="http://www.eprivacy.or.kr">http://www.eprivacy.or.kr</a></li>
        <li>대검찰청 첨단범죄수사센터 전화 : 02-3480-2000 URL : <a href="http://www.spo.go.kr">http://www.spo.go.kr</a></li>
        <li>경찰청 사이버테러대응센터 전화 : 02-393-9112 URL : <a href="http://cyberbureau.police.go.kr">http://cyberbureau.police.go.kr</a></li>
    </ul>
    <br />
    <p>10. 개인정보 처리방침 변경</p>
    <p>법령 및 정책 또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 시에는 변경사항의 시행일 최소 7일 전부터 홈페이지 공지를 통해 고지할 것입니다.</p>
    <ul>
        <li>개인정보 처리방침 변경공고일자 : 2024.09.01.</li>
        <li>개인정보 처리방침 시행일자 : 2024.09.01.</li>
    </ul>


                    </ScrollBox>}

                    <CustomButton
                        style={{gridColumn: 'span 2'}}
                        onClick={handleSubmit}
                        // onClick={() => datePickerRef.current.focus()} // test
                    ><p>상담 신청하기</p></CustomButton>

                </ContactPageForm>
            </StyledContact>
        </BasicContainer>

        <Footer />

    </RootBlock>
  )
}

export default Contact